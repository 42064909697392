import Auth from 'Auth';
import {Alert, Breadcrumb} from 'antd';
import {useFetchReportMetaQuery} from 'api/reportsSlice';
import DataReport from 'components/dataReports/DataReport';
import DataReportForm from 'components/dataReports/DataReportForm';
import PageLeaveWarning from 'hooks/PageLeaveWarning';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {selectIsFormEdited} from 'store/formSlice';

const DataReportPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const reportSlug = location.pathname.split('/')[2];
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const isDirty = useSelector(selectIsFormEdited(`data_report_${reportSlug}`));

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data, isLoading, isSuccess} = useFetchReportMetaQuery(
    {
      slug: reportSlug,
      version,
    },
    {
      skip: !reportSlug || reportSlug === 'new',
    }
  );

  return (
    <>
      <PageLeaveWarning
        message="You have unsaved changes. Are you sure you want to leave?"
        isDirty={isDirty}
      />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/data_report/${editorMode ? 'editor' : ''}`}>
            Data Reports
          </Link>
        </Breadcrumb.Item>
        {data?.path?.map((path) => (
          <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
        ))}
        <Breadcrumb.Item>
          {data?.title ? (
            <Link to={`/data_report/${reportSlug}`}>{data?.title}</Link>
          ) : (
            'New'
          )}
        </Breadcrumb.Item>
        {editorMode && <Breadcrumb.Item>Editor</Breadcrumb.Item>}
      </Breadcrumb>
      <DataReportForm />
      <div className="flex-column">
        {!data && isSuccess && (
          <Alert
            message={<div>Please define a report in the editor.</div>}
            type="info"
            style={{margin: 10}}
          />
        )}
        <DataReport loading={isLoading} />
      </div>
    </>
  );
};

export default DataReportPage;
