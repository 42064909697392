import {CloseCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {Alert, Button, Checkbox} from 'antd';
import {
  useFetchReportSourceColumnsQuery,
  useFetchReportSourcesQuery,
} from 'api/reportsSlice';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import SortButton from 'components/genericComponents/SortButton';
import Tags from 'components/genericComponents/TagsInput';
import {columnTypes} from 'components/seeds/HandsonSettings';
import React, {useMemo, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {updateFormField} from 'store/formSlice';
import styled from 'styled-components';
import {stringToFloat} from 'utils/helpers';

const ColContainer = styled.div`
  display: flex;
  gap: 10px;
  border: 1px solid #ddd;
  align-items: start;
  padding: 8px;
  background-color: white;
  &:hover {
    border-color: #aaa;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  padding: 4px;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LinkedColumn = ({
  column,
  idx,
  parent,
  sourceColumns,
  handleChange,
  handleDelete,
  allCols,
}) => {
  const [name, setName] = useState(column.name);

  const checkIfColNameExists = () => {
    return allCols?.some(
      (col) => col.name === column.name && col.col_id !== idx
    );
  };

  const errors = useMemo(() => {
    if (idx === column.col_id) return [];

    const errors = [];
    if (!column.source_column) {
      errors.push('Source Column is required');
    }
    if (!name) {
      errors.push('Column Name is required');
    } else if (checkIfColNameExists()) {
      errors.push(`Column name must be unique (${column.name})`);
    }
    if (
      column.source_column &&
      sourceColumns &&
      !sourceColumns.some((col) => col.column_name === column.source_column)
    ) {
      errors.push('Source Column does not exist in Source Table');
    }
    return errors;
  }, [column.source_column, name, sourceColumns]);

  const getOptions = () => {
    if (!sourceColumns) return [];
    return sourceColumns
      .map((col) => ({
        value: col.column_name,
        label: col.column_name + ' (' + col.data_type + ')',
        disabled:
          (col.column_name === parent.source_column && idx !== column.col_id) ||
          parent.linked_columns?.some(
            (c, i) => c.source_column === col.column_name && idx !== i
          ),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <>
      <InnerContainer>
        {column.col_id !== idx ? (
          <CloseCircleOutlined
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(idx);
            }}
            style={{
              margin: '8px 0',
            }}
            title="Remove Column"
          />
        ) : null}
        <SelectOptions
          options={getOptions()}
          value={column?.source_column}
          onChange={(value) => handleChange(value, 'source_column')}
          label="Source Column"
          required={true}
          style={{width: '150px'}}
        />
        <InputField
          allowClear={false}
          label="Column Name"
          required={true}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => {
            if (name !== column.name) {
              handleChange(e.target.value, 'name');
            }
          }}
        />
        <SortButton column={column} setColumn={handleChange} />
        <div className="flex-column" style={{gap: 0}}>
          <Checkbox
            checked={column.is_read_only}
            onChange={(e) => handleChange(e.target.checked, 'is_read_only')}
            style={{
              fontSize: '10px',
              margin: 0,
              paddingLeft: 0,
            }}
          >
            Readonly
          </Checkbox>
          {parent.col_id !== idx ? (
            <Checkbox
              checked={column.is_hidden}
              onChange={(e) => handleChange(e.target.checked, 'is_hidden')}
              style={{
                fontSize: '10px',
                margin: 0,
                paddingLeft: 0,
              }}
            >
              Hidden
            </Checkbox>
          ) : null}
        </div>
      </InnerContainer>
      {errors.length ? (
        <Alert
          type="error"
          description={
            <div>
              {errors.map((error, idx) => (
                <div key={idx}>* {error}</div>
              ))}
            </div>
          }
        />
      ) : null}
    </>
  );
};

const SeedColumnForm = ({column, idx, handleDelete, allCols}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const seedId = location.pathname.split('/')[2];

  const setColumn = (value, field) => {
    dispatch(
      updateFormField({
        id: `source_manager_${seedId}`,
        field: `columns.${idx}${field ? '.' + field : ''}`,
        value,
      })
    );
  };

  const {data: sources} = useFetchReportSourcesQuery();
  const {data: sourceColumns} = useFetchReportSourceColumnsQuery(
    column.source_table,
    {
      skip: !column.source_table,
    }
  );

  const checkIfColNameExists = () => {
    return allCols?.some(
      (col) => col.name === column.name && col.col_id !== column.col_id
    );
  };

  const errors = useMemo(() => {
    const errors = [];
    if (column.type === 'autocomplete') {
      if (!column.source_table) {
        errors.push('Source Table is required');
      }
      if (!column.source_column) {
        errors.push('Source Column is required');
      }
      if (
        column.source_column &&
        sourceColumns &&
        !sourceColumns.some((col) => col.column_name === column.source_column)
      ) {
        errors.push('Source Column does not exist in Source Table');
      }
    }
    if (!column.name) {
      errors.push('Column Name is required');
    } else if (checkIfColNameExists()) {
      errors.push(`Column name must be unique (${column.name})`);
    }
    if (column.type === 'dropdown' && !column.source) {
      errors.push('Options are required');
    }
    return errors;
  }, [column.name, column.type, column.source_column, sourceColumns]);

  const getStyleByState = (snapshot) => {
    if (snapshot.isDragging) {
      return {
        backgroundColor: '#eee',
      };
    }
    return {};
  };

  const handleColumnChanged = (value, field, col) => {
    const updatedColumn = {
      ...col,
      [field]: value,
    };

    if (field === 'source_column') {
      updatedColumn.name = value;
      updatedColumn.data_type = sourceColumns.find(
        (c) => c.column_name === value
      )?.data_type;
    } else if (field === 'type') {
      switch (value) {
        case 'text':
          updatedColumn.data_type = 'STRING';
          break;
        case 'date':
          updatedColumn.data_type = 'DATE';
          break;
        case 'numeric':
          updatedColumn.data_type = 'INTEGER';
          break;
        case 'dropdown':
          updatedColumn.data_type = 'STRING';
          break;
        case 'dropdown_numeric':
          updatedColumn.type = 'dropdown';
          updatedColumn.data_type = 'INTEGER';
          break;
        default:
          break;
      }
    }
    if (
      updatedColumn.data_type === 'INTEGER' &&
      updatedColumn.type === 'dropdown'
    ) {
      // if there are no options, set source to empty array
      if (!updatedColumn.source) {
        updatedColumn.source = [];
      } else if (typeof updatedColumn.source === 'string') {
        // if there are options, convert source options to numbers
        const sourceArray = JSON.parse(
          updatedColumn.source.replace(/'/g, '"') ?? '[]'
        );
        const newSource = sourceArray?.map((s) => stringToFloat(s));
        updatedColumn.source = newSource;
      }
    }
    return updatedColumn;
  };

  const handleAddLinkedColumn = () => {
    setColumn({
      ...column,
      linked_columns: [
        ...column.linked_columns,
        {
          source_column: '',
          name: '',
          is_read_only: true,
        },
      ],
    });
  };

  return (
    <Draggable
      key={column.col_id}
      draggableId={`column-${column.col_id}`}
      index={idx}
    >
      {(provided, snapshot) => {
        const style = {
          ...provided.draggableProps.style,
          ...getStyleByState(snapshot),
        };
        return (
          <ColContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            style={style}
          >
            <CloseCircleOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(idx);
              }}
              style={{
                margin: '12px 0',
              }}
              title="Remove Column"
            />
            <Container>
              <InnerContainer>
                <SelectOptions
                  value={
                    column.type === 'dropdown' && column.data_type === 'INTEGER'
                      ? 'dropdown_numeric'
                      : column.type
                  }
                  onChange={(value) =>
                    setColumn(handleColumnChanged(value, 'type', column))
                  }
                  options={columnTypes}
                  style={{width: '150px'}}
                  allowClear={false}
                  label="Column Type"
                  required={true}
                />
                {column.type === 'autocomplete' ? (
                  <SelectOptions
                    options={sources}
                    value={column?.source_table}
                    onChange={(value) => setColumn(value, 'source_table')}
                    label="Source Table"
                    required={true}
                    style={{flexGrow: 1, width: '320px'}}
                  />
                ) : (
                  <InputField
                    label="Column Name"
                    required={true}
                    value={column.name}
                    onChange={(e) => setColumn(e.target.value, 'name')}
                    style={{width: '250px'}}
                  />
                )}
              </InnerContainer>
              {errors.length ? (
                <Alert
                  type="error"
                  description={
                    <div>
                      {errors.map((error, idx) => (
                        <div key={idx}>* {error}</div>
                      ))}
                    </div>
                  }
                />
              ) : null}
              {column.type === 'autocomplete' && (
                <>
                  <LinkedColumn
                    allCols={allCols}
                    column={column}
                    parent={column}
                    idx={column.col_id}
                    sourceColumns={sourceColumns}
                    handleChange={(value, field) => {
                      setColumn(handleColumnChanged(value, field, column));
                    }}
                  />
                  <h6>Linked Columns:</h6>
                  {column.linked_columns?.map((col, idx) => (
                    <LinkedColumn
                      allCols={allCols}
                      key={col.source_column + col.name}
                      column={col}
                      parent={column}
                      idx={idx}
                      sourceColumns={sourceColumns}
                      handleChange={(value, field) => {
                        const updatedLinkedColumns = [...column.linked_columns];
                        updatedLinkedColumns[idx] = handleColumnChanged(
                          value,
                          field,
                          col
                        );
                        setColumn(updatedLinkedColumns, 'linked_columns');
                      }}
                      handleDelete={(idx) =>
                        setColumn(
                          column.linked_columns.filter((c, i) => i !== idx),
                          'linked_columns'
                        )
                      }
                    />
                  ))}
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddLinkedColumn();
                    }}
                    size="small"
                    style={{marginLeft: '24px', width: 'min-content'}}
                    title="Linked columns are edited together, allowing to search and select data as a group"
                  >
                    <PlusCircleOutlined />
                    Add
                  </Button>
                </>
              )}
              {column.type === 'dropdown' && (
                <InnerContainer>
                  <Tags
                    tags={
                      !column.source
                        ? []
                        : typeof column.source === 'string'
                          ? JSON.parse(
                              column?.source.replace(/'/g, '"') ?? '[]'
                            )
                          : column.source
                    }
                    setTags={(value) => {
                      setColumn(value, 'source');
                    }}
                  />
                </InnerContainer>
              )}
              <InnerContainer>
                <Checkbox
                  checked={column.allow_empty}
                  onChange={(e) => setColumn(e.target.checked, 'allow_empty')}
                  style={{
                    fontSize: '10px',
                    margin: 0,
                  }}
                >
                  Allow Empty
                </Checkbox>
                <Checkbox
                  checked={column.allow_invalid}
                  onChange={(e) => setColumn(e.target.checked, 'allow_invalid')}
                  style={{
                    fontSize: '10px',
                    margin: 0,
                  }}
                >
                  Allow Invalid
                </Checkbox>
                <Checkbox
                  checked={column.is_unique}
                  onChange={(e) => setColumn(e.target.checked, 'is_unique')}
                  style={{
                    fontSize: '10px',
                    margin: 0,
                  }}
                >
                  Unique
                </Checkbox>
                {(column.type === 'autocomplete' ||
                  column.type === 'dropdown') && (
                  <Checkbox
                    checked={
                      column.linked_columns?.length
                        ? false
                        : column.allow_multiple
                    }
                    onChange={(e) =>
                      setColumn(e.target.checked, 'allow_multiple')
                    }
                    disabled={column.linked_columns?.length > 0}
                    style={{
                      fontSize: '10px',
                      margin: 0,
                    }}
                  >
                    Multiselect
                  </Checkbox>
                )}
                {column.type !== 'autocomplete' && (
                  <>
                    <Checkbox
                      checked={column.is_read_only}
                      onChange={(e) =>
                        setColumn(e.target.checked, 'is_read_only')
                      }
                      style={{
                        fontSize: '10px',
                        margin: 0,
                      }}
                    >
                      Read Only
                    </Checkbox>
                    <SortButton column={column} setColumn={setColumn} />
                  </>
                )}
              </InnerContainer>
            </Container>
          </ColContainer>
        );
      }}
    </Draggable>
  );
};

export default SeedColumnForm;
