import {embedDashboard} from '@superset-ui/embedded-sdk';
import {useFetchSupersetTokenQuery} from 'api/cmsSlice';
import React, {useCallback, useEffect} from 'react';
import {SUPERSET_URL} from 'utils/consts';

const DASHBOARD_CONFIG = {
  hideTitle: true,
  hideTab: false,
  hideChartControls: false,
  filters: {
    visible: false,
    expanded: false,
  },
};

const TOKEN_REFRESH_INTERVAL = 280000; // 4.67 minutes

const SupersetChartDisplay = ({source, dashboardId}) => {
  const {data: guestToken, refetch} = useFetchSupersetTokenQuery(dashboardId, {
    skip: !dashboardId,
  });

  const embedDashboardInstance = useCallback(
    async (container) => {
      if (!guestToken) return;

      try {
        await embedDashboard({
          dashboardUiConfig: DASHBOARD_CONFIG,
          fetchGuestToken: () => guestToken,
          id: source,
          mountPoint: container,
          supersetDomain: SUPERSET_URL,
        });
      } catch (error) {
        console.error('Failed to embed dashboard:', error);
      }
    },
    [guestToken, source]
  );

  useEffect(() => {
    const container = document.getElementById(`dashboard_${dashboardId}`);
    if (container) {
      embedDashboardInstance(container);
      container.style.width = '100%';
      const iframe = container.children[0];
      iframe.style.cssText = 'height: 80vh; width: 100%; border: none;';
    }
  }, [dashboardId, embedDashboardInstance]);

  useEffect(() => {
    const interval = setInterval(refetch, TOKEN_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [refetch]);

  if (!source || !dashboardId || !guestToken) {
    return null;
  }

  return <div key={guestToken} id={`dashboard_${dashboardId}`} />;
};

export default SupersetChartDisplay;
