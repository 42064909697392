import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {setAccessToken} from 'store/googleAuthSlice';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const useGoogleAuth = () => {
  const dispatch = useDispatch();

  const handleCredentialResponse = useCallback(
    (callback) => {
      const tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/drive.metadata.readonly',
        callback: (tokenResponse) => {
          if (tokenResponse.access_token) {
            dispatch(setAccessToken(tokenResponse.access_token));
            callback(tokenResponse.access_token);
          } else {
            console.error('Failed to get access token');
          }
        },
      });

      tokenClient?.requestAccessToken({prompt: ''});
    },
    [dispatch]
  );

  const initializeGoogleAuth = (callback) => {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://accounts.google.com/gsi/client';
    scriptElement.async = true;
    scriptElement.defer = true;

    scriptElement.onload = () => {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: () => handleCredentialResponse(callback),
      });

      handleCredentialResponse(callback);
    };

    document.head.appendChild(scriptElement);

    return () => {
      document.head.removeChild(scriptElement);
    };
  };

  return {initializeGoogleAuth};
};

export default useGoogleAuth;
