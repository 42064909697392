import {createSelector, createSlice} from '@reduxjs/toolkit';

const defaultHandsonState = {
  draftData: [],
  finalData: [],
  localData: [],
  columns: [],
};

export const handsonSlice = createSlice({
  initialState: {},
  name: 'handson',
  reducers: {
    createTable(state, action) {
      const {id, draftData, finalData, columns} = action.payload;
      state[id] = {
        draftData: JSON.parse(JSON.stringify(draftData)),
        finalData: JSON.parse(JSON.stringify(finalData)),
        localData: JSON.parse(JSON.stringify(draftData)),
        columns,
      };
    },
  },
});

export const {createTable} = handsonSlice.actions;

export const selectHandsonState = (state) => state.handson;

export const selectHandsonData = createSelector(
  [selectHandsonState, (_, tokenId) => tokenId],
  (handsonState, tokenId) => {
    return handsonState[tokenId] || defaultHandsonState;
  }
);

export default handsonSlice.reducer;
