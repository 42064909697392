import {emptySplitApi} from 'api/apiSlice';
import {uniqBy} from 'lodash';
import {handleRTKQError} from 'utils/errorHandler';

const seedsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSeeds: builder.query({
      query: (version) => `/seeds/seed_tree?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'Seeds', id: arg}],
    }),
    fetchSeedData: builder.query({
      query: ({name, version}) => `/seeds/seed/${name}/?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'SeedData', id: `${arg.name}_${result.metadata.version}`},
      ],
    }),
    fetchDbtSchema: builder.query({
      query: (seedId) => `/seeds/dbt_schema/${seedId}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'DBT', id: arg}],
    }),
    fetchColumnOptions: builder.query({
      query: ({name, version}) =>
        `/seeds/column_options/${name}?version=${version}`,
      transformResponse: (response) => {
        const deduped = {};
        // deduplicate stringified options for each source table
        for (const [key, value] of Object.entries(response.options)) {
          deduped[key] = uniqBy(value, JSON.stringify);
        }
        return {
          ...deduped,
          errors: response.errors,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'ColumnOptions', id: `${arg.name}_${arg.version}`},
      ],
    }),
    setSeedData: builder.mutation({
      query: (data) => ({
        url: `/seeds/seed/`,
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['SeedData', 'Seeds'],
    }),
    setSeedMetaData: builder.mutation({
      query: (data) => ({
        url: `/seeds/seed/${data.title}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['SeedData', 'Seeds', 'ColumnOptions'],
    }),
    deleteSeedVersion: builder.mutation({
      query: (data) => ({
        url: `/seeds/seed/${data.title}/${data.version ? `?version=${data.version}` : ''}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SeedData', 'Seeds'],
    }),
    setPresignedUrl: builder.mutation({
      query: (data) => ({
        url: `/seeds/presigned_url/`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchSeedsQuery,
  useFetchSeedDataQuery,
  useFetchDbtSchemaQuery,
  useFetchColumnOptionsQuery,
  useSetSeedDataMutation,
  useSetSeedMetaDataMutation,
  useDeleteSeedVersionMutation,
  useSetPresignedUrlMutation,
} = seedsSlice;
