import {Button, Modal, Spin, message} from 'antd';
import {
  setSchoolHomeroomAssignments,
  shuffleGradeHomeroomAssignments,
} from 'api/apiHandler';
import AssignHomeroomsColumn from 'components/esoy/AssignHomeroomsColumn';
import isEqual from 'fast-deep-equal';
import React, {useEffect, useState} from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import styled from 'styled-components';
import {CROSS_GRADE} from 'utils/consts';
import {handleApiError} from 'utils/errorHandler';
import {getDisplayGrade} from 'utils/helpers';

const Container = styled.div`
  display: flex;
  gap: 8px;
  overflow: auto;
  border: 1px solid lightgrey;
  padding: 8px;
  max-height: 80vh;
  margin-bottom: 20px;
`;

const AssignHomeroomsForGrade = ({
  siteId,
  grade,
  gradeHomerooms,
  crossgradeHomerooms,
  homeroomOrder,
  completed,
  rolloverMode,
  handleCheckAllStudentsAssigned,
  refreshDataSource,
}) => {
  const [loading, setLoading] = useState(false);
  const [showAutoPopulateModal, setShowAutoPopulateModal] = useState(false);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [homerooms, setHomerooms] = useState({...gradeHomerooms});
  const [crossgradeRooms, setCrossgradeRooms] = useState({
    ...crossgradeHomerooms,
  });

  useEffect(() => {
    setLoading(true);
    // if the homerooms have changed, update the state
    if (!isEqual(gradeHomerooms, homerooms)) {
      setHomerooms({...gradeHomerooms});
    }
    if (!isEqual(crossgradeHomerooms, crossgradeRooms)) {
      setCrossgradeRooms({...crossgradeHomerooms});
    }
    setLoading(false);
  }, [gradeHomerooms, crossgradeHomerooms]);

  const onWindowKeyDown = (e) => {
    // disable text selection when shift is pressed
    if (e.shiftKey) {
      document.onselectstart = function () {
        return false;
      };
    }
    // disable open context menu when ctrl is pressed
    if (e.ctrlKey) {
      document.oncontextmenu = () => false;
    }
    if (e.defaultPrevented) {
      return;
    }

    if (e.key === 'Escape') {
      setSelectedStudentIds([]);
    }
  };

  const onWindowClick = (e) => {
    if (e.defaultPrevented) {
      return;
    } else {
      setSelectedStudentIds([]);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    // remove event listeners when component unmounts
    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
    };
  }, []);

  useEffect(() => {
    setAllStudents(getAllStudents());
    handleCheckAllStudentsAssigned();
  }, []);

  const getAllStudents = () => {
    const allStudents = [];
    homeroomOrder.forEach((homeroomId) => {
      const homeroom = gradeHomerooms[homeroomId];
      const homeroomStudents = homeroom.students || [];
      allStudents.push(...homeroomStudents);
    });
    crossgradeHomerooms &&
      Object.values(crossgradeHomerooms).forEach((homeroom) => {
        allStudents.push(...homeroom.students);
      });
    return allStudents;
  };

  const handleToggleStudentSelection = (e, studentId) => {
    if (selectedStudentIds.length === 0) {
      setSelectedStudentIds([studentId]);
    } else if (e.nativeEvent.metaKey || e.nativeEvent.ctrlKey) {
      if (selectedStudentIds.includes(studentId)) {
        setSelectedStudentIds(
          [...selectedStudentIds].filter((id) => id !== studentId)
        );
      } else {
        setSelectedStudentIds([...selectedStudentIds, studentId]);
      }
    } else if (e.nativeEvent.shiftKey) {
      let students = handleSelectMany(selectedStudentIds, studentId);
      setSelectedStudentIds(students);
    } else {
      if (selectedStudentIds.includes(studentId)) {
        setSelectedStudentIds([]);
      } else {
        setSelectedStudentIds([studentId]);
      }
    }
  };

  const onDragEnd = (result) => {
    const {destination, source, draggableId} = result;
    let draggedStudentIds = [...selectedStudentIds];

    // if student is not selected, only drag that student
    if (!draggedStudentIds.includes(draggableId)) {
      draggedStudentIds = [draggableId];
    }
    const draggedStudents = allStudents.filter((student) =>
      draggedStudentIds.includes(student.student_id)
    );

    draggedStudents.forEach((student) => {
      student.hide = false;
    });

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    setLoading(true);
    const getUpdatedHomeroomStudents = (homeroom) => {
      const originalStudents = [...homeroom.students];
      const homeroomStudents = originalStudents.filter(
        (student) => !draggedStudentIds.includes(student.student_id)
      );
      return homeroomStudents;
    };

    const destHomeroom = {
      ...homerooms,
      ...crossgradeRooms,
    }[destination.droppableId];

    const tempHomerooms = {
      ...homerooms,
    };

    const tempCrossgradeRooms = {
      ...crossgradeRooms,
    };

    for (const homeroom of Object.values({
      ...homerooms,
      ...crossgradeRooms,
    })) {
      const updatedStudents = getUpdatedHomeroomStudents(homeroom);
      if (homeroom.grade === CROSS_GRADE) {
        tempCrossgradeRooms[homeroom.homeroom_id].students = updatedStudents;
      } else {
        tempHomerooms[homeroom.homeroom_id].students = updatedStudents;
      }
    }

    const destStudents = [...destHomeroom.students];
    destStudents.splice(destination.index, 0, ...draggedStudents);

    if (destHomeroom.grade === CROSS_GRADE) {
      tempCrossgradeRooms[destHomeroom.homeroom_id].students = destStudents;
    } else {
      tempHomerooms[destHomeroom.homeroom_id].students = destStudents;
    }

    const allStudentsAfterDrag = [];
    homeroomOrder.forEach((homeroomId) => {
      const homeroom = tempHomerooms[homeroomId];
      allStudentsAfterDrag.push(...homeroom.students);
    });
    tempCrossgradeRooms &&
      Object.values(tempCrossgradeRooms).forEach((homeroom) => {
        allStudentsAfterDrag.push(...homeroom.students);
      });

    saveNewHomeroomState({
      homerooms: tempHomerooms,
      crossgradeRooms: tempCrossgradeRooms,
    });

    setAllStudents(allStudentsAfterDrag);
    setHomerooms(tempHomerooms);
    setCrossgradeRooms(tempCrossgradeRooms);
  };

  const handleSelectMany = (selectedStudentIds, studentId) => {
    // select all students between last selected student and current selected student
    const lastSelectedStudentId =
      selectedStudentIds[selectedStudentIds.length - 1];
    const lastSelectedStudentIndex = allStudents.findIndex(
      (student) => student.student_id === lastSelectedStudentId
    );
    const currentSelectedStudentIndex = allStudents.findIndex(
      (student) => student.student_id === studentId
    );
    const startIndex = Math.min(
      lastSelectedStudentIndex,
      currentSelectedStudentIndex
    );
    const endIndex = Math.max(
      lastSelectedStudentIndex,
      currentSelectedStudentIndex
    );
    const studentsToSelect = allStudents.slice(startIndex, endIndex + 1);
    return [
      ...new Set([
        ...selectedStudentIds,
        ...studentsToSelect.map((student) => student.student_id),
      ]),
    ];
  };

  const autoPopulateHomerooms = () => {
    setShowAutoPopulateModal(false);
    if (gradeHomerooms.unassigned.students.length === 0) {
      message.error({
        content: 'No students to auto-populate.',
        duration: 2,
      });
      return;
    }
    setLoading(true);
    shuffleGradeHomeroomAssignments(siteId, grade, rolloverMode)
      .then((response) => {
        const tempHomerooms = {
          ...homerooms,
        };
        response.data.forEach((homeroom) => {
          tempHomerooms[homeroom.homeroom_id] = homeroom;
        });
        tempHomerooms.unassigned = {
          ...tempHomerooms['unassigned'],
          students: [],
        };
        setHomerooms(tempHomerooms);
      })
      .catch((error) => {
        error.data ??= {};
        error.data.detail = 'Error auto-populating homerooms.';
        handleApiError(error);
      })
      .finally(() => {
        handleCheckAllStudentsAssigned();
        setLoading(false);
      });
  };

  const saveNewHomeroomState = (stateToSave) => {
    const key = 'save';
    message.loading({content: 'Saving change...', key});

    const mapStudentIdToHomerooms = (homerooms) => {
      if (!homerooms) {
        return {};
      }
      const keys = Object.keys(homerooms);
      const mappedHomerooms = {};
      for (const key of keys) {
        const homeroom = homerooms[key];
        mappedHomerooms[homeroom.homeroom_id] = {
          students: homeroom.students.map((student) => student.student_id),
        };
      }
      return mappedHomerooms;
    };

    let homeroomsStudentIds = mapStudentIdToHomerooms(stateToSave.homerooms);
    let crossGradeStudentIds = mapStudentIdToHomerooms(
      stateToSave.crossgradeRooms
    );

    const assignmentsToPost = {
      [grade]: homeroomsStudentIds,
      [CROSS_GRADE]: crossGradeStudentIds,
    };

    setLoading(true);
    // Save the new state to the server.
    setSchoolHomeroomAssignments(siteId, assignmentsToPost)
      .then((response) => {
        message.success({
          content: 'Change saved.',
          key,
          duration: 2,
        });
      })
      .catch((error) => {
        error.data ??= {};
        error.data.detail = 'Error assigning homerooms.';
        handleApiError(error);
      })
      .finally(() => {
        handleCheckAllStudentsAssigned();
        refreshDataSource();
      });
  };

  const onBeforeDragStart = (start) => {
    if (completed) {
      return;
    }
    const {draggableId} = start;
    const studentIsSelected = selectedStudentIds.includes(draggableId);

    // if multiple students are selected, hide all selected students not being dragged
    if (studentIsSelected && selectedStudentIds.length > 1) {
      // get all selected students that are not being dragged
      const selectedStudents = allStudents.filter(
        (student) =>
          selectedStudentIds.includes(student.student_id) &&
          student.student_id !== draggableId
      );

      // hide all selected students not being dragged
      selectedStudents.forEach((student) => {
        student.hide = true;
      });
    }
  };

  const handleSortStudents = (sortedStudents, homeroomId) => {
    const tempHomerooms = {
      ...homerooms,
    };
    const tempCrossgradeHomerooms = {
      ...crossgradeRooms,
    };
    tempHomerooms[homeroomId]
      ? (tempHomerooms[homeroomId] = {
          ...tempHomerooms[homeroomId],
          students: sortedStudents,
        })
      : (tempCrossgradeHomerooms[homeroomId] = {
          ...tempCrossgradeHomerooms[homeroomId],
          students: sortedStudents,
        });

    setHomerooms(tempHomerooms);
    setCrossgradeRooms(tempCrossgradeHomerooms);
    setAllStudents(getAllStudents());
  };

  const getCrossgradeStudents = (students) => {
    let homeroomStudents = [];
    const assignmentGrade =
      rolloverMode === 'pre' ? 'next_year_grade' : 'grade_level';
    students.forEach((student) => {
      let homeroomStudent = {...student};
      const studentIsInGrade = student[assignmentGrade] === grade;
      if (!studentIsInGrade) {
        homeroomStudent.wrongGrade = true;
      } else {
        homeroomStudent.wrongGrade = false;
      }
      homeroomStudents.push(homeroomStudent);
    });
    return homeroomStudents;
  };

  return (
    <Spin spinning={loading} size="large">
      <div className="flex-row" style={{justifyContent: 'space-between'}}>
        <h3>{getDisplayGrade(grade)}</h3>
        <Button
          onClick={() => setShowAutoPopulateModal(true)}
          type="primary"
          style={{marginBottom: 10}}
        >
          Auto-Populate Homerooms
        </Button>
      </div>
      <Modal
        open={showAutoPopulateModal}
        onOk={autoPopulateHomerooms}
        onCancel={() => setShowAutoPopulateModal(false)}
        okText="Yes"
        cancelText="No"
        title="Auto-Populate Homerooms"
      >
        <p>
          This action will assign all students in this grade that currently
          appear in the <strong>'Unassigned'</strong> column.
        </p>
        <p>Students will be distributed by gender.</p>
        <span>Are you sure you want to continue?</span>
      </Modal>
      <Container>
        <DragDropContext
          onBeforeDragStart={onBeforeDragStart}
          onDragEnd={onDragEnd}
        >
          {homeroomOrder &&
            homeroomOrder.map((columnId) => {
              const column = homerooms[columnId];
              return (
                <AssignHomeroomsColumn
                  key={column.grade + column.homeroom_id}
                  column={column}
                  students={column.students}
                  selectedStudentIds={selectedStudentIds}
                  handleToggleSelect={handleToggleStudentSelection}
                  handleSortStudents={(sortedStudents) =>
                    handleSortStudents(sortedStudents, column.homeroom_id)
                  }
                  disabled={completed}
                />
              );
            })}
          {crossgradeRooms &&
            Object.entries(crossgradeRooms).map((homeroom) => {
              const students = getCrossgradeStudents(homeroom[1].students);
              return (
                <AssignHomeroomsColumn
                  key={homeroom[0]}
                  column={{
                    ...homeroom[1],
                    title: homeroom[1].title + ' (Cross-Grade)',
                  }}
                  students={students}
                  selectedStudentIds={selectedStudentIds}
                  handleToggleSelect={handleToggleStudentSelection}
                  handleSortStudents={(sortedStudents) =>
                    handleSortStudents(sortedStudents, homeroom[0])
                  }
                  disabled={completed}
                />
              );
            })}
        </DragDropContext>
      </Container>
    </Spin>
  );
};

export default AssignHomeroomsForGrade;
