import {ExportOutlined} from '@ant-design/icons';
import {Collapse} from 'antd';
import axios from 'axios';
import DataPortalDisplay from 'components/dataPortal/DataPortalDisplay';
import React, {useEffect, useState} from 'react';

const {Panel} = Collapse;

const ZendeskPreview = ({artifact}) => {
  const credentials = btoa(
    `${process.env.REACT_APP_ZENDESK_EMAIL}:${process.env.REACT_APP_ZENDESK_API_TOKEN}`
  );
  const domain = artifact.source.split('/')[2];
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    getPreview(artifact).then((preview) => setPreview(preview));
  }, [artifact]);

  const getPreview = async (artifact) => {
    const url = `https://${domain}/api/v2/help_center/articles/${artifact.id}.json`;
    axios
      .get(url, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      })
      .then((response) => {
        setPreview(response.data.article.body);
      });
  };

  return (
    <div
      dangerouslySetInnerHTML={{__html: preview}}
      style={{
        padding: '20px',
      }}
    />
  );
};

const ArtifactPreview = ({artifact}) => {
  if (artifact.type === 'zendesk') {
    return <ZendeskPreview artifact={artifact} />;
  } else if (artifact.type === 'data_portal') {
    return (
      <div
        style={{
          padding: '20px',
          height: '500px',
          width: '480px',
        }}
      >
        <DataPortalDisplay slug={artifact.id} />
      </div>
    );
  } else {
    return (
      <iframe src={artifact.source} width="500" height="500" frameBorder="0" />
    );
  }
};

const Artifacts = ({artifacts = []}) => {
  if (!artifacts.length) {
    return null;
  }
  return (
    <Collapse
      accordion
      style={{
        borderBottom: '1px solid #d9d9d9',
        maxHeight: '76vh',
        overflowY: 'auto',
        width: '500px',
      }}
    >
      {artifacts.map((artifact, index) => (
        <Panel
          header={artifact.name}
          key={index}
          extra={
            <a
              href={artifact.source}
              target="_blank"
              rel="noreferrer"
              style={{marginLeft: '10px'}}
            >
              <ExportOutlined />
            </a>
          }
          style={{
            overflowY: 'auto',
          }}
        >
          <ArtifactPreview artifact={artifact} />
        </Panel>
      ))}
    </Collapse>
  );
};

export default Artifacts;
