import {createSelector, createSlice} from '@reduxjs/toolkit';
import {generateUniqueId} from 'utils/helpers';

const defaultChatState = {
  infoItem: null,
  messages: [],
  session: null,
  suggestions: [],
};

export const chatSlice = createSlice({
  initialState: {},
  name: 'chat',
  reducers: {
    addMessage(state, action) {
      const {id, data} = action.payload;
      const newMessage = {
        id: generateUniqueId(state[id].messages.map((m) => m.id)),
        time: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
        ...data,
      };
      state[id].messages.push(newMessage);
    },
    setInfoItem(state, action) {
      const {id, data} = action.payload;
      state[id].infoItem = data;
    },
    createChat(state, action) {
      const {id, session} = action.payload;
      state[id] = {
        messages: [],
        session,
        suggestions: [
          'Who is the ACOO?',
          'How is GPA calculated?',
          'What is the average SAT score?',
        ],
      };
    },
    setSuggestions(state, action) {
      const {id, data} = action.payload;
      state[id].suggestions = data;
    },
  },
});

export const {addMessage, setInfoItem, createChat, setSuggestions} =
  chatSlice.actions;

export const selectChatState = (state) => state.chat;

export const selectChats = createSelector([selectChatState], (chatState) => {
  return {
    chats: Object.keys(chatState),
  };
});

export const selectChatData = createSelector(
  [selectChatState, (_, tokenId) => tokenId],
  (chatState, tokenId) => {
    if (!tokenId) return defaultChatState;
    return chatState[tokenId] || defaultChatState;
  }
);

export const selectArtifacts = createSelector(
  [selectChatState, (_, tokenId) => tokenId],
  (chatState, tokenId) => {
    if (!tokenId) return [];
    // reduce messages to artifacts, dedup artifacts
    const artifacts = chatState[tokenId].messages.reduce((acc, message) => {
      if (message.artifacts) {
        message.artifacts.forEach((artifact) => {
          const existingArtifact = acc.find(
            (a) => a.id === artifact.id && a.type === artifact.type
          );
          if (!existingArtifact) {
            acc.push(artifact);
          }
        });
      }
      return acc;
    }, []);
    return artifacts;
  }
);

export default chatSlice.reducer;
