import Auth from 'Auth';
import {useFetchPagesQuery} from 'api/cmsSlice';
import Catalog from 'components/genericComponents/Catalog';
import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

const DataPortalOverviewPage = () => {
  const location = useLocation();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;
  const params = new URLSearchParams(location.search);

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data: pages, isLoading: pagesLoading} = useFetchPagesQuery(version);

  const extraTags = [
    {
      color: 'red',
      id: 'draft',
      label: 'Draft',
      name: 'Draft',
      value: 'draft',
    },
    {
      color: 'orange',
      id: 'schema_changed',
      label: 'Unpublished Changes',
      name: 'Unpublished Changes',
      value: 'schema_changed',
    },
    {
      color: 'blue',
      id: 'search_context',
      label: 'Search Context',
      name: 'Search Context',
      value: 'search_context',
    },
    {
      color: 'green',
      id: 'spotlight',
      label: 'Spotlight',
      name: 'Spotlight',
      value: 'spotlight',
    },
  ];

  return (
    <div>
      <h1>Data Portal Overview</h1>
      <Catalog
        buttons={[
          {
            label: 'Create New Page',
            href: `/data_portal/new/editor`,
          },
        ]}
        editorPermission={Auth.permissions.access_to_data_portal_editor}
        extraTags={extraTags}
        loading={pagesLoading}
        items={pages}
        path="data_portal"
      />
    </div>
  );
};

export default DataPortalOverviewPage;
