import {DownloadOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {Alert, Button, Spin} from 'antd';
import {useFetchReportMetaQuery, useFetchReportQuery} from 'api/reportsSlice';
import axios from 'axios';
import DataTable from 'components/genericComponents/DataTable';
import useThrottle from 'hooks/Throttle';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {TRACK_API_BASE_URL} from 'utils/consts';
import {handleApiError} from 'utils/errorHandler';

const DataReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const reportSlug = location.pathname.split('/')[2];
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [queryUrl, setQueryUrl] = useState();
  const [loadingCsv, setLoadingCsv] = useState(false);

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data: reportMeta, isLoading: loadingMeta} = useFetchReportMetaQuery(
    {
      slug: reportSlug,
      version,
    },
    {
      skip: !reportSlug || reportSlug === 'new',
    }
  );

  const {
    data: reportData,
    isLoading: loadingData,
    isFetching,
  } = useFetchReportQuery(
    {
      slug: reportSlug,
      version,
      queryUrl,
    },
    {
      skip: !reportSlug || reportSlug === 'new',
    }
  );

  useEffect(() => {
    if (reportData?.error?.length) {
      handleApiError({
        status: 199,
        data: {detail: reportData.error},
      });
    }
  }, [reportData]);

  const throttledRequest = useThrottle(setQueryUrl);

  useEffect(() => {
    if (!reportMeta) return;
    if (params.toString().length) return;
    // if there are no query params, check if there are any default sort columns and add them
    const newParams = new URLSearchParams();
    const defaultSortColumns =
      reportMeta.columns?.filter((column) => column.sort) || [];
    let sortParam = '';
    defaultSortColumns.forEach((column) => {
      sortParam += `${column.alias}:${column.sort},`;
    });
    if (sortParam) {
      newParams.set('sort', sortParam.slice(0, -1));
    }
    setParams(newParams.toString());
  }, [reportMeta, params]);

  useEffect(() => {
    const newQueryUrl = getQueryParams();
    if (newQueryUrl !== queryUrl) {
      throttledRequest(newQueryUrl);
    }
  }, [location.search]);

  const setParams = (newParams) => {
    if (newParams === params.toString()) {
      return;
    }
    navigate(`${location.pathname}?${newParams}`);
  };

  const getQueryParams = useCallback(() => {
    let url = '';
    const params = new URLSearchParams(location.search);
    for (const entry of params.entries()) {
      if (entry[1] && !entry[0] !== 'version') {
        entry[0] = entry[0].replace('filter_', '');
        url += `${entry[0]}=${entry[1]}&`;
      }
    }
    return url;
  }, [location.search, reportSlug]);

  const downloadReport = () => {
    if (!reportSlug) return;
    setLoadingCsv(true);
    let url = queryUrl.replace(/page=\d+&page_size=\d+/, 'page_size=10000');
    axios({
      url: `${TRACK_API_BASE_URL}/data_reports/reports/csv/${reportSlug}?version=${version}&${url}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = reportMeta?.title?.replace(/ /g, '_') || reportSlug;
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => {
        setLoadingCsv(false);
      });
  };

  return (
    <>
      {reportData?.report && (
        <div>
          <Button
            onClick={downloadReport}
            style={{float: 'right'}}
            loading={loadingCsv}
          >
            <DownloadOutlined />
            Download
          </Button>
        </div>
      )}
      <h1>{reportMeta?.title}</h1>
      <Spin spinning={loadingData || loadingMeta || isFetching}>
        <div style={{margin: 10}}>
          {reportMeta?.long_description ? (
            <h4>{reportMeta.long_description}</h4>
          ) : null}
          <DataTable
            params={params}
            setParams={setParams}
            data={reportData}
            columns={reportMeta?.columns?.filter(
              (column) => column.column_type !== 'FILTER'
            )}
            filters={reportMeta?.columns?.filter(
              (column) => column.column_type === 'FILTER'
            )}
            bordered
            size="small"
            key={
              JSON.stringify(reportData) +
              JSON.stringify(reportMeta) +
              loadingData +
              loadingMeta
            }
          />
          {editorMode && reportData?.sql && (
            <Alert
              message="Generated SQL Query:"
              type="info"
              style={{margin: '10px 0'}}
              description={
                <div>
                  <p>{reportData.sql}</p>
                  <Button
                    type="primary"
                    onClick={() =>
                      navigator.clipboard.writeText(reportData.sql)
                    }
                  >
                    Copy to Clipboard
                  </Button>
                </div>
              }
            />
          )}
        </div>
      </Spin>
    </>
  );
};

export default DataReport;
