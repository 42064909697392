import Auth from 'Auth';
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Modal,
  Spin,
  message,
} from 'antd';
import {
  useDeleteSeedVersionMutation,
  useFetchDbtSchemaQuery,
  useFetchSeedDataQuery,
  useSetSeedMetaDataMutation,
} from 'api/seedsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import EditorButton from 'components/genericComponents/EditorButton';
import HelpModal from 'components/genericComponents/HelpModal';
import InputField from 'components/genericComponents/Input';
import PublishButton from 'components/genericComponents/PublishButton';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import SeedColumnForm from 'components/seeds/SeedColumnForm';
import React, {useEffect, useMemo, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {selectFormState, setFormData, updateFormField} from 'store/formSlice';
import {store} from 'store/store';
import styled from 'styled-components';
import {generateUniqueId, getLetterByIndex} from 'utils/helpers';

const {Panel} = Collapse;

const SeedColumnList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SeedForm = () => {
  const location = useLocation();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;
  const params = new URLSearchParams(location.search);
  const seedId = location.pathname.split('/')[2];

  const dispatch = useDispatch();
  const formState = useSelector(
    (state) => selectFormState(state, `source_manager_${seedId}`),
    shallowEqual
  );

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {
    data: seedData,
    isLoading,
    isFetching,
  } = useFetchSeedDataQuery(
    {
      name: seedId,
      version,
    },
    {
      skip: !seedId,
    }
  );
  const {data: dbtSchema, isLoading: loadingDbt} =
    useFetchDbtSchemaQuery(seedId);
  const {data: tags} = useFetchTagsQuery();
  const {data: categories} = useFetchCategoriesQuery();

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createOrUpdateTag] = useCreateOrUpdateTagMutation();
  const [deleteSeedVersion] = useDeleteSeedVersionMutation();
  const [setSeedMetaData] = useSetSeedMetaDataMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    if (seedData?.metadata) {
      let columns = [];

      if (seedData?.metadata?.columns?.length) {
        columns = seedData.metadata.columns;
      } else if (seedData?.data?.[0]) {
        columns = Object.keys(seedData.data[0]).map((key) => ({
          col_id: generateUniqueId([], 'int'),
          key,
          linked_columns: [],
          name: key,
          type: 'text',
        }));
      } else if (seedData?.draft_data?.[0]) {
        columns = Object.keys(seedData.draft_data[0]).map((key) => ({
          col_id: generateUniqueId([], 'int'),
          key,
          linked_columns: [],
          name: key,
          type: 'text',
        }));
      }

      const enable_autoload = columns.some(
        (col) => col.type === 'autocomplete'
      );
      const autoload_column = enable_autoload
        ? columns.find((col) => col.type === 'autocomplete')?.col_id
        : null;

      dispatch(
        setFormData({
          id: `source_manager_${seedId}`,
          data: {
            ...seedData.metadata,
            columns,
            enable_autoload,
            autoload_column,
          },
        })
      );
    }
  }, [seedData]);

  const allCols = useMemo(() => {
    let cols = [];
    formState?.columns?.forEach((col) => {
      cols.push({
        name: col.name,
        data_type: col.data_type,
        col_id: col.col_id,
      });
      if (col.linked_columns?.length) {
        col.linked_columns.forEach((linkedCol, i) => {
          cols.push({
            name: linkedCol.name,
            data_type: linkedCol.data_type,
            col_id: i,
          });
        });
      }
    });
    return cols;
  }, [formState?.columns]);

  const allErrors = useMemo(() => {
    if (!seedData) {
      return ['Seed data not found'];
    }
    let errors = [];
    if (!dbtSchema?.length) {
      errors.push(
        'The data in this source has no effect downstream, since no DBT schema is defined'
      );
    } else if (!allCols?.length) {
      errors.push('Please add columns to the configuration');
    } else {
      dbtSchema?.forEach((dbtCol) => {
        const tableCol = allCols.find((c) => c.name === dbtCol.name);
        if (!tableCol) {
          errors.push(
            `Column ${dbtCol.name} exists in the DBT schema but is missing in the TRACK configuration`
          );
        } else if (dbtCol.type !== tableCol.data_type) {
          /**
           * check if the column type in dbt schema matches the column type in the configuration
           * if the type in dbt is 'DATE' or 'INTEGER' and the type in the configuration is not, it is considered a mismatch
           * since the data will not be converted correctly between the s3 csv and the dbt schema
           */
          if (
            (dbtCol.type === 'DATE' || dbtCol.type === 'INTEGER') &&
            tableCol.data_type !== dbtCol.type
          ) {
            errors.push(
              `Column ${dbtCol.name} has a type mismatch. The column type in the DBT schema is ${dbtCol.type} but the column type in the TRACK configuration is ${tableCol.data_type}`
            );
          }
        }
      });
      // check if all columns in seed exist in dbt schema
      allCols?.forEach((col) => {
        const dbtCol = dbtSchema?.find((c) => c.name === col.name);
        if (!dbtCol) {
          errors.push(
            `Column ${col.name} exists in the TRACK configuration but is missing in the DBT schema`
          );
        }
      });
    }
    return errors;
  }, [dbtSchema, allCols]);

  const autoloadOptions = useMemo(() => {
    return formState?.columns
      ?.filter((col) => col.type === 'autocomplete')
      .map((col) => {
        return {
          label: col.linked_columns?.length
            ? `${col.name} + ${col.linked_columns?.map((c) => c.name).join(' + ')}`
            : col.name,
          value: col.col_id,
        };
      });
  }, [formState?.columns]);

  const handleAddColumn = () => {
    const newCol = {
      col_id: generateUniqueId(formState.columns, 'int'),
      linked_columns: [],
      name: `Column ${getLetterByIndex(formState.columns.length)}`,
      type: 'text',
    };
    handleSeedChanged('columns', [...formState.columns, newCol]);
  };

  const handleDeleteColumn = (idx) => {
    const newCols = formState.columns.filter((_, i) => i !== idx);
    handleSeedChanged('columns', newCols);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formState.columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    handleSeedChanged('columns', items);
  };

  const handleSeedChanged = (key, value) => {
    dispatch(
      updateFormField({
        id: `source_manager_${seedId}`,
        field: key,
        value,
      })
    );
  };

  const handleSave = (publish) => {
    message.loading({
      content: 'Saving...',
      key: 'save',
    });
    const currentFormState =
      store.getState().form.forms[`source_manager_${seedId}`];
    const seed = {
      ...currentFormState,
      published: publish,
      title: seedId,
    };
    setSeedMetaData(seed)
      .unwrap()
      .then(() => {
        dispatch(
          setFormData({
            id: `source_manager_${seedId}`,
            data: seed,
          })
        );
        const url = new URL(window.location.href);
        url.searchParams.set('version', 'latest');
        window.history.pushState({}, '', url);
        message.success({
          content: 'Saved successfully',
          key: 'save',
        });
      });
  };

  const handlePublish = (publish, version) => {
    message.loading({
      content: publish ? 'Publishing...' : 'Unpublishing...',
      key: 'publish',
    });
    const currentFormState =
      store.getState().form.forms[`source_manager_${seedId}`];
    setSeedMetaData({
      published: publish,
      title: seedId,
      version: version || currentFormState.version,
    })
      .unwrap()
      .then(() => {
        dispatch(
          setFormData({
            id: `source_manager_${seedId}`,
            data: {
              ...currentFormState,
              published: publish,
            },
          })
        );
        message.success({
          content: publish
            ? 'Published successfully'
            : 'Unpublished successfully',
          key: 'publish',
        });
      });
  };

  return (
    <>
      <Drawer
        closable={false}
        extra={
          <PublishButton
            formId={`source_manager_${seedId}`}
            handlePublish={handlePublish}
            handleSave={handleSave}
            isLoading={isLoading || isFetching}
            isValid={formState?.columns?.length > 0}
          />
        }
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          minHeight: 'calc(100% - 64px)',
          overflowY: 'auto',
          position: 'fixed',
        }}
        title={<h3>Source Schema Editor</h3>}
        width={600}
      >
        <Spin spinning={isLoading}>
          <HelpModal
            title="Trouble with the schema?"
            content={
              <>
                <h5>Type Mismatch</h5>
                <p>
                  The column type defined in the DBT schema doesn’t match the
                  type in the TRACK configuration. This can lead to data
                  conversion errors.
                  <br />
                  If the column uses <strong>autocomplete</strong>, make sure
                  the <strong>data_type</strong> in DBT matches the actual
                  source column type.
                </p>

                <h5>Missing Columns in TRACK Configuration</h5>
                <p>
                  Any columns not listed in the TRACK configuration won’t be
                  editable in this tool. That can result in incomplete or
                  inconsistent data.
                </p>

                <h5>Missing Columns in DBT Schema</h5>
                <p>
                  Columns that aren’t defined in the DBT schema won’t appear in
                  the external table or flow into downstream systems.
                </p>

                <h5>DBT Schema Not Defined</h5>
                <p>
                  This source has no effect downstream because no DBT schema is
                  currently defined. If you believe the schema should already
                  exist, confirm the following:
                </p>
                <p>
                  1. The source is defined and deployed in DBT for the correct
                  environment.
                  <br />
                  2. The <strong>location</strong> field in the DBT
                  configuration is set correctly:{' '}
                  <strong>
                    "&#123;&#123;env_var('DBT_TRACK_MANAGED_SOURCES','')&#125;&#125;&#60;SOURCE_NAME&#62;/"
                  </strong>
                  <br />
                  3. The <strong>name</strong> field matches the{' '}
                  <strong>&#60;SOURCE_NAME&#62;</strong> in the location path.
                </p>
                <p>
                  Not sure how to define the schema? Check the&nbsp;
                  <a
                    href="https://app.getguru.com/card/TG9MXa6c/TRACK-Source-Manager-Overview"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TRACK Source Manager Overview
                  </a>
                  .
                </p>
              </>
            }
          />
          <div className="flex-column">
            <h3>{formState?.title}</h3>
            {allErrors.length ? (
              <Spin spinning={loadingDbt}>
                <Alert
                  type="error"
                  description={
                    <>
                      {allErrors.map((error, idx) => (
                        <p key={idx} style={{paddingLeft: '0'}}>
                          * {error}
                        </p>
                      ))}
                    </>
                  }
                />
              </Spin>
            ) : null}
            <SelectOptions
              label="Category"
              onAdd={(name) => {
                createOrUpdateCategory({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handleSeedChanged('category', res);
                  });
              }}
              onChange={(value) =>
                handleSeedChanged('category', {
                  id: value.id,
                  name: value.title,
                  parent: value.parent,
                })
              }
              options={categories?.children}
              tree={true}
              value={formState?.category?.id}
            />
            <SelectOptions
              label="Tags"
              mode="tags"
              multiple
              onAdd={(name) => {
                createOrUpdateTag({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handleSeedChanged('tags', [...formState.tags, res]);
                  });
              }}
              onChange={(value) => {
                handleSeedChanged(
                  'tags',
                  tags?.filter((c) => value.includes(c.id))
                );
              }}
              options={tags?.map((c) => ({
                value: c.id,
                label: c.name,
                color: c.color,
              }))}
              style={{width: '100%'}}
              value={formState?.tags?.map((t) => t.id)}
            />
            <InputField
              label="Notes"
              onChange={(e) =>
                handleSeedChanged('long_description', e.target.value)
              }
              value={formState?.long_description}
              multiline={true}
              style={{width: '100%'}}
            />
            <div>
              <Alert
                description={
                  <div>
                    Notes:
                    <ul>
                      <li>
                        <b>
                          Column names and types must match the schema in DBT.
                        </b>
                      </li>
                      <li>
                        Unpublished sources are only visible to editors, and
                        changes cannot be finalized.
                      </li>
                      <li>Published sources are visible to all users.</li>
                    </ul>
                  </div>
                }
                type="info"
                style={{marginBottom: '20px'}}
              />
            </div>
            <h4>Columns</h4>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="columns">
                {(provided) => (
                  <SeedColumnList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {formState?.columns?.map((column, idx) => (
                      <SeedColumnForm
                        column={column}
                        handleDelete={() => handleDeleteColumn(idx)}
                        idx={idx}
                        key={column?.col_id}
                        allCols={allCols}
                      />
                    ))}
                    {provided.placeholder}
                  </SeedColumnList>
                )}
              </Droppable>
            </DragDropContext>

            <Button
              type="primary"
              onClick={handleAddColumn}
              style={{margin: '10px 0', width: '150px'}}
            >
              Add Column
            </Button>
            <Divider />

            <h4>Unique Identifier</h4>
            <Alert
              description={
                <div>
                  Select all columns that make up a unique entry in this seed.
                  <br />
                  If there are multiple columns, they will be concatenated.
                  <br />
                  If none are selected, all entries will be marked as
                  duplicates.
                </div>
              }
              type="success"
              style={{marginBottom: '20px'}}
            />
            <SelectOptions
              label="Unique Identifier"
              multiple={true}
              onChange={(val) => handleSeedChanged('primary_key', val)}
              options={allCols.map((col) => col.name)}
              style={{width: '100%'}}
              value={formState?.primary_key || []}
            />
            <Divider />
            <h4>Autoload</h4>
            <Alert
              description={
                <div>
                  Select the columns you want to autoload from the source table.
                  <br />
                  Autoload is used to populate the seed automatically. It can
                  only be enabled if there are autocomplete columns in the
                  configuration.
                </div>
              }
              type="success"
              style={{marginBottom: '20px'}}
            />
            <Checkbox
              disabled={
                !formState?.columns?.some((col) => col.type === 'autocomplete')
              }
              checked={formState?.enable_autoload}
              onChange={(e) => {
                handleSeedChanged('enable_autoload', e.target.checked);
              }}
            >
              Enable
            </Checkbox>
            {formState?.enable_autoload ? (
              <div className="flex-column" style={{margin: '10px 0'}}>
                <Alert
                  description={
                    <div>
                      If applicable, it is recommended to add the Read Only
                      checkbox attribute for the selected Autoload columns. This
                      will ensure end users can only edit allowed columns.
                    </div>
                  }
                  type="info"
                  style={{marginBottom: '20px'}}
                />
                <SelectOptions
                  label="Columns to Populate"
                  onChange={(val) => {
                    handleSeedChanged('autoload_column', val);
                  }}
                  options={autoloadOptions}
                  required={true}
                  style={{width: '100%'}}
                  value={formState.autoload_column}
                />
              </div>
            ) : null}
            <Divider />
            {formState?.version_history?.length > 1 ? (
              <Collapse ghost expandIconPosition="end">
                <Panel header="Version History" key="1">
                  {formState?.version_history?.map((version) => {
                    const _version = {
                      ...version,
                      slug: seedId,
                    };
                    return (
                      <VersionItem
                        currentVersion={formState.version}
                        handleDelete={() => deleteSeedVersion(version)}
                        handlePublish={(publish) =>
                          handlePublish(publish, _version.version)
                        }
                        key={version.version}
                        version={_version}
                      />
                    );
                  })}
                </Panel>
              </Collapse>
            ) : null}
          </div>
        </Spin>
      </Drawer>
      <EditorButton
        drawerVisible={drawerVisible}
        formId={`source_manager_${seedId}`}
        key={seedId + editorMode}
        path={`/source_manager/${seedId}`}
        setDrawerVisible={setDrawerVisible}
      />
      <Modal
        closable={true}
        footer={null}
        onCancel={() => setModalVisible(false)}
        open={modalVisible}
        title="How to Use This Page"
      >
        <div>
          <ul>
            <li>Double click or start typing to open a cell dropdown.</li>
            {seedData?.metadata?.enable_autoload && (
              <li>
                Click <b>Load New Entries</b> to load any new entries from the
                source table. Entries that already appear in the table will not
                be duplicated or overwritten.
              </li>
            )}
            <li>
              Deleting data:
              <ul>
                <li>
                  Selecting a row/rows and hitting <b>Delete</b> will remove the
                  data from editable columns only.
                </li>
                <li>
                  Selecting a row/rows, right clicking, and selecting{' '}
                  <b>Remove Row/s</b> will remove the entire row.
                </li>
              </ul>
            </li>
            <li>
              Click <b>Check Statuses</b> to review changes before saving.
            </li>
            <li>
              Hit <b>Save Draft</b> before clicking <b>Finalize Changes</b>.
            </li>
            <li>
              Sorting by multiple columns: hold <b>Ctrl/Cmd</b> and click on the
              column headers.
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default SeedForm;
