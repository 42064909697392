import Auth from 'Auth';
import {Spin, Tag} from 'antd';
import {useFetchPageQuery} from 'api/cmsSlice';
import DataTableDisplay from 'components/dataPortal/DataTableDisplay';
import SupersetDisplay from 'components/dataPortal/SupersetDisplay';
import DocViewer from 'components/genericComponents/GoogleDocViewer';
import PDFViewer from 'components/genericComponents/PDFViewer';
import RichTextDisplay from 'components/genericComponents/RichTextDisplay';
import mermaid from 'mermaid';
import React, {useEffect, useMemo, useRef} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {setContrast} from 'utils/helpers';

const MermaidChart = ({chart}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      mermaid.initialize({startOnLoad: true});
      mermaid.contentLoaded();
    }
  }, [chart]);

  return (
    <pre className="mermaid" ref={chartRef}>
      {`${chart.replace('```mermaid', '').replace('```', '')}`}
    </pre>
  );
};

const DataPortalDisplay = ({slug = ''}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageSlug =
    location.pathname.split('/')[2] !== 'new'
      ? location.pathname.split('/')[2]
      : '';
  const params = new URLSearchParams(location.search);
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data, isLoading, refetch, isSuccess} = useFetchPageQuery(
    {slug: pageSlug ?? slug, version},
    {skip: !pageSlug && !slug}
  );

  useEffect(() => {
    if (data) refetch();
  }, [pageSlug, editorMode, version]);

  useEffect(() => {
    if (data) {
      data.widgets.forEach((widget) => {
        params.set(widget.source, params.get(widget.source) || '');
      });
    }
  }, [data]);

  const setParams = (newParams, reportId) => {
    if (params.get(reportId) !== newParams) {
      params.set(reportId, newParams);
      navigate(`${location.pathname}?${params.toString()}`);
    }
  };

  const getQueryParams = (reportId) => {
    const urlParams = new URLSearchParams(params.get(reportId) || '');
    for (const entry of urlParams.entries()) {
      urlParams.set(entry[0].replace('filter_', ''), entry[1]);
    }
    return urlParams;
  };

  return (
    <Spin spinning={isLoading}>
      <h1>{data?.title}</h1>
      <div className="flex-row" style={{marginBottom: '20px', gap: 0}}>
        {data?.tags?.map((tag) => (
          <Link
            to={`/data_portal/${editorMode ? 'editor' : ''}?tag=${encodeURIComponent(tag.name)}`}
            key={tag.name}
          >
            <Tag
              color={tag.color}
              style={{
                borderColor:
                  setContrast(tag.color) === '#000000d9'
                    ? '#d9d9d9'
                    : tag.color,
                color: setContrast(tag.color),
                cursor: 'pointer',
                marginLeft: 5,
                marginRight: 1,
              }}
            >
              {tag.name}
            </Tag>
          </Link>
        ))}
      </div>
      <div className="flex-column" style={{marginBottom: '20px', gap: '30px'}}>
        {data?.widgets.map((widget) => {
          switch (widget.type) {
            case 'text':
              return widget.content ? (
                <RichTextDisplay key={widget.id} value={widget.content} />
              ) : null;
            case 'table':
              return widget.source ? (
                <DataTableDisplay
                  key={widget.id}
                  reportSlug={widget.source}
                  params={getQueryParams(widget.source)}
                  setParams={(value) => setParams(value, widget.source)}
                />
              ) : null;
            case 'dashboard':
              return widget.source && widget.title ? (
                <SupersetDisplay
                  key={widget.id}
                  source={widget.source}
                  dashboardId={widget.title}
                />
              ) : null;
            case 'google':
            case 'pdf':
              return widget.source ? (
                widget.source.includes('.google.') ? (
                  <DocViewer
                    docLink={widget.source}
                    key={widget.id}
                    widget={widget}
                  />
                ) : (
                  <PDFViewer widget={widget} key={widget.id} />
                )
              ) : null;
            case 'mermaid':
              return widget.content ? (
                <MermaidChart key={widget.id} chart={widget.content} />
              ) : null;
            default:
              return null;
          }
        })}
        {!data?.widgets.length && isSuccess && <div>No widgets found</div>}
      </div>
    </Spin>
  );
};

export default DataPortalDisplay;
