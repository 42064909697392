import {QuestionCircleOutlined} from '@ant-design/icons';
import {Button, Modal} from 'antd';
import React, {useState} from 'react';

const HelpModal = ({title, content}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setModalVisible(true)}
        size="large"
        style={{position: 'absolute', right: 0, zIndex: 1, fontSize: '1.5em'}}
        title={title}
        type="text"
      >
        <QuestionCircleOutlined />
      </Button>
      <Modal
        closable={true}
        footer={null}
        onCancel={() => setModalVisible(false)}
        open={modalVisible}
        title={title}
        width={1000}
      >
        {content}
      </Modal>
    </div>
  );
};

export default HelpModal;
