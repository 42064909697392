import {Badge, Button, Dropdown, Modal, Space} from 'antd';
import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  resetForm,
  selectFormState,
  selectHasPublishedVersion,
  selectIsFormEdited,
  selectVersionsCount,
} from 'store/formSlice';

const PublishButton = ({
  formId,
  handleDelete,
  handlePublish,
  handleSave,
  isLoading,
  isValid,
}) => {
  const dispatch = useDispatch();

  const isDirty = useSelector(selectIsFormEdited(formId));
  const hasPublishedVersion = useSelector(selectHasPublishedVersion(formId));
  const isOnlyVersion = useSelector(selectVersionsCount(formId)) === 1;
  const currentState = useSelector((state) => selectFormState(state, formId));

  const [confirmAction, setConfirmAction] = useState(null);

  const handleDiscard = () => {
    dispatch(resetForm(formId));
  };

  const confirmationConfigs = {
    deleteVersion: {
      title: 'Delete This Version',
      content:
        'Are you sure you want to delete this version? This action cannot be undone.',
      action: () => handleDelete(currentState?.version),
    },
    deleteAll: {
      title: 'Delete All Versions',
      content:
        'Are you sure you want to delete all versions? This action cannot be undone.',
      action: () => handleDelete(),
    },
    discard: {
      title: 'Discard Changes',
      content: 'Are you sure you want to discard all unsaved changes?',
      action: handleDiscard,
    },
    unpublish: {
      title: 'Unpublish',
      content:
        'Are you sure you want to unpublish all versions? Non-editor users will no longer have access.',
      action: () => handlePublish(false),
    },
    unpublishAndDelete: {
      title: 'Unpublish and Delete',
      content:
        'Are you sure you want to unpublish and delete this item? This action cannot be undone. All versions will be deleted.',
      action: () => handleDelete(),
    },
  };

  const showConfirmation = (actionKey) => {
    setConfirmAction(actionKey);
  };

  const handleConfirm = async () => {
    if (confirmAction && confirmationConfigs[confirmAction]) {
      try {
        await confirmationConfigs[confirmAction].action();
        setConfirmAction(null);
      } catch (error) {
        // Handle error if needed
      }
    }
  };

  const menuItems = useMemo(() => {
    const options = [];
    if (isDirty) {
      options.push({
        disabled: !isValid,
        key: 'saveAndPublish',
        label: 'Save and publish',
        onClick: () => {
          handleSave(true);
        },
      });
      options.push({
        disabled: !isValid,
        key: 'save',
        label: 'Save',
        onClick: () => {
          handleSave(false);
        },
      });
      options.push({
        key: 'discard',
        label: formId.endsWith('new') ? 'Discard' : 'Discard changes',
        onClick: () => showConfirmation('discard'),
      });
    } else if (currentState?.published) {
      options.push({
        key: 'unpublish',
        label: 'Unpublish',
        onClick: () => showConfirmation('unpublish'),
        danger: true,
      });
    } else if (hasPublishedVersion) {
      // Another version is published
      options.push({
        disabled: !isValid,
        key: 'publish',
        label: 'Publish this version',
        onClick: () => handlePublish(true),
      });
      options.push({
        key: 'unpublish',
        label: 'Unpublish current live version',
        onClick: () => showConfirmation('unpublish'),
        danger: true,
      });
    } else {
      // No version is published
      options.push({
        disabled: !isValid,
        key: 'publish',
        label: 'Publish',
        onClick: () => handlePublish(true),
      });
    }
    if (handleDelete && !formId.endsWith('new')) {
      if (hasPublishedVersion) {
        options.push({
          key: 'unpublishAndDelete',
          label: 'Unpublish and delete',
          onClick: () => {
            showConfirmation('unpublishAndDelete');
          },
          danger: true,
        });
      } else {
        if (!isOnlyVersion) {
          options.push({
            key: 'deleteVersion',
            label: 'Delete this version',
            onClick: () => showConfirmation('deleteVersion'),
            danger: true,
          });
        }
        options.push({
          key: 'delete',
          label: 'Delete',
          onClick: () => showConfirmation('deleteAll'),
          danger: true,
        });
      }
    }

    return options;
  }, [
    currentState?.published,
    handleDelete,
    hasPublishedVersion,
    isDirty,
    isLoading,
  ]);

  const getStatusBadge = () => {
    if (isDirty) {
      return (
        <Badge
          status="warning"
          text={
            currentState?.published
              ? 'Unsaved changes (Published)'
              : 'Unsaved changes (Draft)'
          }
        />
      );
    }
    if (currentState?.published) {
      return <Badge status="success" text="Published" />;
    }
    if (hasPublishedVersion) {
      return <Badge status="default" text="Different version published" />;
    }
    return <Badge status="default" text="Draft" />;
  };

  const getButton = () => {
    // get the first menu item to set the main button text and action. If this button is disabled, the check if there is a different button to use
    const getMainButtonProps = () => {
      if (menuItems.length > 0) {
        const mainButton = menuItems[0];
        if (!mainButton.disabled) {
          return mainButton;
        } else {
          return menuItems.find((item) => !item.disabled) || mainButton;
        }
      }
    };

    // if there are more than 1 item in the dropdown, show a dropdown button
    const hasDropdownItems = menuItems.length > 1;
    // populate the dropdown menu with the items, minus the one which is the main button
    const dropdownItems = menuItems.filter(
      (item) => item.key !== getMainButtonProps().key
    );

    if (hasDropdownItems) {
      return (
        <Dropdown.Button
          danger={getMainButtonProps().danger}
          loading={isLoading}
          menu={{
            items: dropdownItems,
          }}
          onClick={getMainButtonProps().onClick}
          trigger={['click']}
          type="primary"
        >
          {getMainButtonProps().label}
        </Dropdown.Button>
      );
    }

    return (
      <Button
        danger={getMainButtonProps().danger}
        disabled={!isValid}
        loading={isLoading}
        onClick={getMainButtonProps().onClick}
        type="primary"
      >
        {getMainButtonProps().label}
      </Button>
    );
  };

  return (
    <>
      <Space direction="horizontal" size="middle">
        {getStatusBadge()}
        {getButton()}
      </Space>

      <Modal
        cancelText="Cancel"
        confirmLoading={isLoading}
        okText="Confirm"
        onCancel={() => setConfirmAction(null)}
        onOk={handleConfirm}
        open={!!confirmAction}
        title={confirmAction ? confirmationConfigs[confirmAction].title : ''}
      >
        {confirmAction && confirmationConfigs[confirmAction].content}
      </Modal>
    </>
  );
};

export default PublishButton;
