import Auth from 'Auth';
import {Button, Modal, Segmented, Switch, message} from 'antd';
import {
  useFetchColumnOptionsQuery,
  useFetchDbtSchemaQuery,
  useFetchSeedDataQuery,
  useSetPresignedUrlMutation,
} from 'api/seedsSlice';
import axios from 'axios';
import _ from 'lodash';
import Papa from 'papaparse';
import React, {useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {updateFormField} from 'store/formSlice';
import {statuses} from 'utils/consts';

// statuses:
// - removed: the row is empty
// - duplicate: the row has duplicate primary keys or unique columns
// - edited: the row has been edited
// - invalid: the row has invalid cells (list of invalid cells)
// - blank: the row has empty cells (list of empty cells)
// - newRow: the row is new

const TableButtons = ({
  addPrimaryKeys,
  allTableColumns,
  getPrimaryKeyCols,
  hotRef,
  initialSort,
  isDiff,
  isDirty,
  loading,
  primaryKeyCols,
  runActions,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;
  const params = new URLSearchParams(location.search);
  const seedId = location.pathname.split('/')[2];

  const [confirmAction, setConfirmAction] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [showColumns, setShowColumns] = useState(false);
  const [statusCounts, setStatusCounts] = useState({
    removed: 0,
    duplicate: 0,
    edited: 0,
    invalid: 0,
    blank: 0,
    newRow: 0,
  });

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data: dbtSchema} = useFetchDbtSchemaQuery(seedId);
  const {data: seedData, refetch} = useFetchSeedDataQuery(
    {
      name: seedId,
      version,
    },
    {
      skip: !seedId,
    }
  );
  const {data: autocompleteOptions, isLoading: loadingOptions} =
    useFetchColumnOptionsQuery(
      {
        name: seedId,
        version,
      },
      {
        skip: !seedId,
      }
    );

  const [setPresignedUrl] = useSetPresignedUrlMutation();

  const confirmationConfigs = {
    discard: {
      title: 'Discard Draft',
      content:
        'Are you sure you want to discard this draft? This cannot be undone.',
      action: () => handleSave('discard'),
    },
    saveFinal: {
      title: 'Save Final',
      content: 'Are you sure you want to finalize these changes?',
      action: () => handleSave('final'),
    },
  };

  const showConfirmation = (actionKey) => {
    setConfirmAction(actionKey);
  };

  const segmentedOptions = useMemo(() => {
    const options = [
      {label: 'All', value: 'all'},
      ...Object.keys(statusCounts).map((status) => ({
        disabled: statusCounts[status] === 0,
        label: `${statuses[status]?.label} (${statusCounts[status]})`,
        value: status,
      })),
    ];
    return options;
  }, [statusCounts]);

  const handleConfirm = async () => {
    if (confirmAction && confirmationConfigs[confirmAction]) {
      try {
        await confirmationConfigs[confirmAction].action();
        setConfirmAction(null);
      } catch (error) {
        // Handle error if needed
      }
    }
  };

  const handleSave = (version) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot || loading) return;
    const data = hot.getSourceData();

    const dataToSave = [];
    const colsToSave = new Set();
    dbtSchema?.forEach((col) => {
      colsToSave.add(col.name);
    });
    for (const col of allTableColumns) {
      if (
        col.name.includes('___original') ||
        col.name === '' ||
        col.name === '___primaryKey'
      )
        continue;
      colsToSave.add(col.name);
      if (col.linkedCols) {
        for (const linkedCol of col.linkedCols) {
          colsToSave.add(linkedCol.name);
        }
      }
    }

    if (!colsToSave) {
      message.error({
        content: 'Please save the configuration for this seed first',
        key: 'save',
      });
      return;
    }
    data?.forEach((row) => {
      const newRow = {};
      for (const col of colsToSave) {
        if (version === 'discard') {
          newRow[col] = row[`${col}___original`] ?? '';
        } else {
          newRow[col] = row[col] ?? '';
        }
      }
      // if the row values are all empty or undefined, don't save the row
      if (Object.values(newRow).join('') !== '') {
        dataToSave.push(newRow);
      }
    });

    const originalData =
      version === 'final' ? seedData?.data : seedData?.draft_data;

    filterByStatus('all');

    // check if dataToSave is different from the original data
    if (_.isEqual(dataToSave, originalData)) {
      message.info({
        content: 'No changes to save',
        key: 'save',
        duration: 2,
      });
      return;
    }

    // convert data to csv file
    const csvData = Papa.unparse(dataToSave);
    const csvBlob = new Blob([csvData], {type: 'text/csv'});
    const title =
      version === 'final' ? `${seedId}/${seedId}.csv` : `${seedId}_draft.csv`;

    setPresignedUrl({
      filename: title,
      content_type: 'text/csv',
    })
      .unwrap()
      .then((response) => {
        try {
          axios
            .put(response.presigned_url, csvBlob, {
              headers: {
                'Content-Type': 'text/csv',
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                message.loading({
                  content: `Uploading ${percentCompleted}%`,
                  key: 'save',
                });
              },
            })
            .then(() => {
              refetch();
              message.success({
                content: 'Changes saved',
                key: 'save',
                duration: 2,
              });
            });
          return response.presigned_url;
        } catch (error) {
          message.error({
            content: 'Error saving changes',
            key: 'save',
          });
        }
      })
      .catch((error) => {
        message.error({
          content: 'Error saving changes',
          key: 'save',
        });
      });
  };

  const setDataFromSource = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    setLoading(true);
    const autoloadColumn = allTableColumns.find(
      (col) => col.id === seedData?.metadata?.autoload_column
    );
    if (!autoloadColumn) {
      message.error({
        content: 'No autoload column found',
        key: 'autoload',
      });
      setLoading(false);
      return;
    }
    const source = autoloadColumn.source_table;
    const autoloadPrimaryKey = autoloadColumn.name;
    const rows = autocompleteOptions?.[source];

    if (!rows) {
      message.error({
        content: `Autoload source ${source} not found in database!`,
        key: 'autoload',
      });
      setLoading(false);
      return;
    }
    if (!rows.length) {
      message.info({
        content: `No rows found in ${source}`,
        key: 'autoload',
      });
      setLoading(false);
      return;
    }
    filterByStatus('all');

    const autoloadPrimaryKeyCols = getPrimaryKeyCols([autoloadPrimaryKey]);
    const keyCols = autoloadPrimaryKeyCols.map((col) =>
      allTableColumns.find((c) => c.name === col)
    );

    // TODO: check if the source_column name is different from the column name to avoid unnecessary renaming
    const newDataWithSourceColumnRenamed = rows.map((row) => {
      const newRow = {};
      for (const col of keyCols) {
        newRow[col.name] = row[col.source_column];
      }
      return newRow;
    });

    const data = hot.getSourceData();

    const hotDataWithAutoloadPrimaryKeys = addPrimaryKeys(
      data,
      autoloadPrimaryKeyCols,
      '___autoloadPrimaryKey'
    );
    const newDataWithAutoloadPrimaryKeys = addPrimaryKeys(
      newDataWithSourceColumnRenamed,
      autoloadPrimaryKeyCols,
      '___autoloadPrimaryKey'
    );

    const newTableData = [];
    for (const row of newDataWithAutoloadPrimaryKeys) {
      const newRow = {};
      const match = hotDataWithAutoloadPrimaryKeys.find(
        (r) => r.___autoloadPrimaryKey === row.___autoloadPrimaryKey
      );
      for (const col of allTableColumns) {
        if (!col.name.includes('___') && col.name !== '') {
          newRow[col.name] = match ? match[col.name] : row[col.name];
        } else {
          newRow[col.name] = match ? match[col.name] : '';
        }
      }
      newTableData.push(newRow);
    }

    const newTableDataWithPrimaryKeys = addPrimaryKeys(
      newTableData,
      primaryKeyCols,
      '___primaryKey'
    );

    // add old data that is not in the new data to the new data
    for (const row of hotDataWithAutoloadPrimaryKeys) {
      if (
        !newDataWithAutoloadPrimaryKeys.find(
          (r) => r.___autoloadPrimaryKey === row.___autoloadPrimaryKey
        )
      ) {
        newTableDataWithPrimaryKeys.push(row);
      }
    }

    // check if the new data is different from the old data
    if (_.isEqual(newTableDataWithPrimaryKeys, data)) {
      message.info({
        content: 'No new entries found',
        key: 'autoload',
        duration: 2,
      });
      setLoading(false);
      return;
    }

    dispatch(
      updateFormField({
        id: `table_editor_${seedId}`,
        field: 'table_data',
        value: JSON.stringify(newTableDataWithPrimaryKeys),
      })
    );
    hot.updateData(newTableDataWithPrimaryKeys);
    setTimeout(() => {
      message.success({
        content: 'New entries loaded',
        key: 'autoload',
        duration: 2,
      });
      hot.getPlugin('multiColumnSorting').sort(initialSort);
      setLoading(false);
    }, 1000);
  };

  const filterByStatus = (status) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    setSelectedStatus(status);
    const filtersPlugin = hot.getPlugin('filters');
    filtersPlugin.clearConditions(0);
    if (status !== 'all') {
      filtersPlugin.addCondition(0, 'contains', [status]);
    }
    filtersPlugin.filter();
  };

  const getRowStatuses = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    // set the button to loading
    // setCalculatingStatuses(true);
    const actions = [];

    clearFilters();

    const tableData = hot.getSourceData();

    const _blanks = {};
    const _duplicates = {};
    const _edited = {};
    const _invalid = {};
    const _newRows = [];
    const _removed = [];

    const uniqueColsVals = {};
    const uniqueCols = allTableColumns.filter(
      (col) => col.is_unique && !col.name.endsWith('___original')
    );
    for (const col of uniqueCols) {
      const colVals = tableData.map((row) => {
        const val = col.linkedCols
          ? col.linkedCols.map((linkedCol) => row[linkedCol.name]).join('___')
          : row[col.name];
        return val;
      });
      uniqueColsVals[col.name] = colVals;
    }
    const primaryKeys = tableData.map((row) => row.___primaryKey);

    const newDataCols = allTableColumns.filter(
      (col) =>
        !!col.name &&
        !col.name.endsWith('___original') &&
        col.name !== '___primaryKey'
    );
    const originalDataCols = allTableColumns.filter((col) =>
      col.name.endsWith('___original')
    );

    for (let i = 0; i < tableData.length; i++) {
      const rowData = tableData[i];
      const cellMeta = hot.getCellMeta(i, 0);
      const originalData = originalDataCols.map((col) => rowData[col.name]);
      const newData = newDataCols.map((col) => rowData[col.name]);

      const currentStatusValue = hot.getSourceDataAtCell(i, 0);
      const statusValue = [];

      // check if the row has been removed:
      // if all the visible cells in the row are empty, and the row is not new, mark the row as removed
      if (newData.every((val) => !val) && originalData.some((val) => !!val)) {
        _removed.push(i);
        for (const status of Object.keys(statuses)) {
          if (status === 'removed') {
            if (!cellMeta.removed) {
              actions.push({
                method: 'setCellMeta',
                row: i,
                col: 0,
                key: 'removed',
                value: 'Removed',
              });
            }
          } else if (cellMeta[status]) {
            actions.push({
              method: 'setCellMeta',
              row: i,
              col: 0,
              key: status,
              value: false,
            });
          }
        }
        statusValue.push('removed');
      } else {
        // check if the row is a new row:
        // if some visible cells in the row are not empty, and original data is empty, mark the row as new
        if (originalData.every((val) => !val) && newData.some((val) => val)) {
          _newRows.push(i);
          if (!cellMeta.newRow) {
            actions.push({
              method: 'setCellMeta',
              row: i,
              col: 0,
              key: 'newRow',
              value: 'New Row',
            });
          }
          statusValue.push('newRow');
        } else {
          // check if the row has been edited, and if so, which columns
          checkIfRowIsEdited(
            _edited,
            actions,
            cellMeta,
            i,
            newData,
            newDataCols,
            originalData,
            statusValue
          );
        }

        // check if the row has blanks that are required
        checkIfRowHasBlanks(
          _blanks,
          actions,
          cellMeta,
          i,
          newDataCols,
          rowData,
          statusValue
        );

        // check if the row has invalid cells
        checkIfRowHasInvalids(
          _invalid,
          actions,
          cellMeta,
          i,
          newDataCols,
          statusValue,
          tableData
        );

        // check if the row has duplicate primary keys or unique columns
        checkIfRowHasDuplicates(
          _duplicates,
          actions,
          cellMeta,
          i,
          primaryKeys,
          statusValue,
          tableData,
          uniqueColsVals
        );
      }

      if (statusValue.length && statusValue.join('\n') !== currentStatusValue) {
        actions.push({
          method: 'setDataAtCell',
          row: i,
          col: 0,
          value: statusValue.join('\n'),
        });
      }
    }

    if (actions.length) runActions(actions);

    const rowStatuses = {
      blank: Object.keys(_blanks).length,
      duplicate: Object.keys(_duplicates).length,
      edited: Object.keys(_edited).length,
      invalid: Object.keys(_invalid).length,
      newRow: _newRows.length,
      removed: _removed.length,
    };

    setStatusCounts(rowStatuses);
    // setCalculatingStatuses(false);
  };

  const clearFilters = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    setSelectedStatus('all');
    const filtersPlugin = hot.getPlugin('filters');
    const sortPlugin = hot.getPlugin('multiColumnSorting');
    sortPlugin.clearSort();
    filtersPlugin.clearConditions();
    filtersPlugin.filter();
  };

  const checkIfRowIsEdited = (
    _edited,
    actions,
    cellMeta,
    i,
    newData,
    newDataCols,
    originalData,
    statusValue
  ) => {
    // check if the row has been edited, and if so, which columns
    const _editedCols = [];
    newDataCols.forEach((col, j) => {
      if (newData[j] !== originalData[j]) {
        _editedCols.push(col.name);
      }
    });
    // if the row has been edited, add the edited columns to the edited object and the statusValue array
    if (_editedCols.length) {
      if (!_edited[i]) _edited[i] = [];
      _edited[i].push(_editedCols.join('\n'));
      statusValue.push('edited');
    }
    // update the cell meta with the edited columns
    if (_editedCols.join('\n') !== (cellMeta.edited ?? '')) {
      actions.push({
        method: 'setCellMeta',
        row: i,
        col: 0,
        key: 'edited',
        value: _editedCols.length
          ? 'Edited Columns:\n' + _editedCols.join('\n')
          : '',
      });
    }
  };

  const checkIfRowHasDuplicates = (
    _duplicates,
    actions,
    cellMeta,
    i,
    primaryKeys,
    statusValue,
    tableData,
    uniqueColsVals
  ) => {
    // check if the row has duplicate primary keys or unique columns
    const _duplicateCols = [];
    const primaryKey = primaryKeys[i];
    if (Object.keys(uniqueColsVals)?.length) {
      const uniqueCols = Object.keys(uniqueColsVals);
      for (const col of uniqueCols) {
        const val = uniqueColsVals[col][i];
        if (uniqueColsVals[col].filter((v) => v === val).length > 1) {
          _duplicateCols.push(col);
        }
      }
    }
    // if the table has a primary key, check if the primary key is a duplicate
    if (
      primaryKeyCols.length &&
      primaryKeys.filter((key) => key === primaryKey).length > 1
    ) {
      _duplicateCols.push('Primary Key');
    } else {
      // if the table has no primary key, check if the row has duplicate values in the entire row
      if (tableData.filter((row) => _.isEqual(row, tableData[i])).length > 1) {
        _duplicateCols.push('Entire Row');
      }
    }
    // if the row has duplicate primary keys or unique columns, add to the duplicates object and the statusValue array
    if (_duplicateCols.length) {
      if (!_duplicates[i]) _duplicates[i] = [];
      _duplicates[i].push(_duplicateCols.join('\n'));
      statusValue.push('duplicate');
    }
    // update the cell meta with the duplicate columns
    if (_duplicateCols.join('\n') !== (cellMeta.duplicate ?? '')) {
      actions.push({
        method: 'setCellMeta',
        row: i,
        col: 0,
        key: 'duplicate',
        value: _duplicateCols.length
          ? 'Duplicate Columns:\n' + _duplicateCols.join('\n')
          : '',
      });
    }
  };

  const checkIfRowHasInvalids = (
    _invalid,
    actions,
    cellMeta,
    i,
    newDataCols,
    statusValue,
    tableData
  ) => {
    const _invalidCols = [];
    newDataCols.forEach((col) => {
      // only check cells that are not allowed to be invalid
      if (
        col.allow_invalid ||
        col.name.endsWith('___original') ||
        col.name === ''
      )
        return;
      let invalidMessage;
      // if the cell is a dropdown or autocomplete, the value must be in the source
      if (col.type === 'dropdown' || col.type === 'autocomplete') {
        invalidMessage = 'Value not in source';
      } else if (col.data_type === 'INTEGER') {
        invalidMessage = 'Value must be an integer';
      } else if (col.data_type === 'DATE') {
        invalidMessage = 'Value must be a date';
      } else {
        invalidMessage = 'Invalid Value';
      }

      !col.validator(tableData[i][col.name], (valid) => {
        if (!valid) {
          _invalidCols.push(col.name + ': ' + invalidMessage);
        }
      });
    });
    // if the row has invalid cells, add to the invalid object and the statusValue array
    if (_invalidCols.length) {
      if (!_invalid[i]) _invalid[i] = [];
      _invalid[i].push(_invalidCols.join('\n'));
      statusValue.push('invalid');
    }
    // update the cell meta with the invalid columns
    if (_invalidCols.join('\n') !== (cellMeta.invalid ?? '')) {
      actions.push({
        method: 'setCellMeta',
        row: i,
        col: 0,
        key: 'invalid',
        value: _invalidCols.length
          ? 'Invalid Values:\n' + _invalidCols.join('\n')
          : '',
      });
    }
  };

  const checkIfRowHasBlanks = (
    _blanks,
    actions,
    cellMeta,
    i,
    newDataCols,
    rowData,
    statusValue
  ) => {
    const _blankCols = [];
    newDataCols.forEach((col) => {
      // only check cells that are not allowed to be empty
      if (
        col.allow_empty ||
        col.name.endsWith('___original') ||
        col.name === ''
      )
        return;
      const blank = !rowData[col.name];
      if (blank) {
        _blankCols.push(col.name);
      }
    });
    // if the row has blank cells, add to the blanks object and the statusValue array
    if (_blankCols.length) {
      if (!_blanks[i]) _blanks[i] = [];
      _blanks[i].push(_blankCols.join('\n'));
      statusValue.push('blank');
    }
    // update the cell meta with the blank columns
    if (_blankCols.join('\n') !== (cellMeta.blank ?? '')) {
      actions.push({
        method: 'setCellMeta',
        row: i,
        col: 0,
        key: 'blank',
        value: _blankCols.length
          ? 'Blank Columns:\n' + _blankCols.join('\n')
          : '',
      });
    }
  };

  const handleShowColumns = (show) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    const hiddenColumnsPlugin = hot.getPlugin('hiddenColumns');
    const hiddenColumnsIndexes = allTableColumns
      ?.filter((col) => col.is_hidden && !col.name.endsWith('___original'))
      .map((col) => col.index);
    if (show) {
      hiddenColumnsPlugin.showColumn(...hiddenColumnsIndexes);
    } else {
      hiddenColumnsPlugin.hideColumn(...hiddenColumnsIndexes);
    }
    hot.render();
    setShowColumns(show);
  };

  return (
    <div>
      <div className="flex-row" style={{margin: '30px 0'}}>
        {seedData?.metadata?.enable_autoload && (
          <Button
            onClick={setDataFromSource}
            style={{marginBottom: '20px'}}
            type="primary"
            loading={loadingOptions}
          >
            Load New Entries
          </Button>
        )}
        <Button onClick={getRowStatuses} type="primary">
          Check Statuses
        </Button>
        <span style={{flex: 1}} />
        <Button
          disabled={!isDirty} // disable if there are no changes to save
          onClick={() => handleSave('draft')}
          type="primary"
        >
          Save Draft
        </Button>
        <Button
          disabled={!isDirty && !isDiff} // disable if there are no local or remote changes to discard
          onClick={() => showConfirmation('discard')}
        >
          Discard Draft
        </Button>
        <Button
          disabled={!isDiff || !seedData?.metadata?.published || isDirty} // disable if there are no changes to save, or the seed is not published, or there are local unsaved changes
          onClick={() => showConfirmation('saveFinal')}
          type="primary"
        >
          Finalize Changes
        </Button>
      </div>
      <div
        className="flex-row"
        style={{flexWrap: 'wrap', alignItems: 'center'}}
      >
        <Segmented
          defaultValue="all"
          onChange={filterByStatus}
          options={segmentedOptions}
          value={selectedStatus}
        />
        <div style={{flex: 1}} />
        {allTableColumns.some((col) => col.is_hidden) ? (
          <Switch
            checkedChildren="Hide Columns"
            unCheckedChildren="Show All Columns"
            checked={showColumns}
            onChange={handleShowColumns}
          />
        ) : null}
        <Button onClick={clearFilters} type="primary">
          Clear Filters
        </Button>
      </div>
      <Modal
        cancelText="Cancel"
        okText="Confirm"
        onCancel={() => setConfirmAction(null)}
        onOk={handleConfirm}
        open={!!confirmAction}
        title={confirmAction ? confirmationConfigs[confirmAction].title : ''}
      >
        {confirmAction ? confirmationConfigs[confirmAction].content : ''}
      </Modal>
    </div>
  );
};

export default TableButtons;
