import {
  DislikeOutlined,
  ExportOutlined,
  FormOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import Auth from 'Auth';
import {
  Checkbox,
  Comment,
  Divider,
  Form,
  Input,
  Popconfirm,
  Tooltip,
  message,
} from 'antd';
import {useSetFeedbackMutation} from 'api/searchSlice';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectChatData} from 'store/chatSlice';

const ChatMessage = React.memo(
  ({message: messageData, messageIndex, sessionId, openPageForm = null}) => {
    const editorMode = Auth.permissions.access_to_data_portal_editor;
    const [form] = Form.useForm();

    const [sendFeedback] = useSetFeedbackMutation();

    const {messages = []} = useSelector((state) =>
      selectChatData(state, sessionId)
    );

    const reasons = [
      {label: 'Inaccurate', value: 'inaccurate'},
      {label: 'Unhelpful', value: 'unhelpful'},
      {label: 'Off-topic', value: 'off_topic'},
      {label: 'Sensitive', value: 'sensitive'},
    ];

    const getUserQuestion = useCallback(() => {
      if (messageIndex > 0) {
        return messages[messageIndex - 1].content;
      }
      return '';
    }, [messageIndex, messages]);

    const handleSendFeedback = (values, rating) => {
      sendFeedback({
        user_question: getUserQuestion(),
        llm_response: messageData.rawText,
        session: sessionId,
        rating,
        issue_flags: values.reason,
        free_text: values.feedback,
      })
        .unwrap()
        .then(() => {
          message.success('Thank you for your feedback!');
        })
        .catch((error) => {
          message.error('Failed to send feedback');
        });
    };

    const actions = useMemo(() => {
      if (messageData.author === 'Bot') {
        const temp = [
          <LikeOutlined
            key="positive-feedback"
            onClick={() => {
              handleSendFeedback({}, 'up');
            }}
            title="Good response"
          />,
          <Popconfirm
            key="negative-feedback"
            icon={null}
            title={
              <Form form={form}>
                <Form.Item name="reason" noStyle>
                  <Checkbox.Group options={reasons} />
                </Form.Item>
                <Form.Item name="feedback" noStyle>
                  <Input.TextArea
                    placeholder="Please provide feedback"
                    rows={4}
                  />
                </Form.Item>
              </Form>
            }
            onConfirm={() => {
              form.validateFields().then((values) => {
                handleSendFeedback(values, 'down');
                form.resetFields();
              });
            }}
            okText="Send"
            cancelText="Cancel"
          >
            <DislikeOutlined key="negative-feedback" title="Bad response" />
          </Popconfirm>,
        ];

        if (editorMode && openPageForm) {
          temp.push(
            <span
              onClick={openPageForm}
              title="Create a new page from this message"
            >
              <FormOutlined />
              <span style={{paddingLeft: '8px'}}>Create Page</span>
            </span>
          );
        }
        return temp;
      }
      return [];
    }, [editorMode, messageData.author, openPageForm]);

    return (
      <>
        <Comment
          actions={actions}
          author={messageData.author}
          content={
            <>
              {messageData.preamble ? (
                <div>
                  <p>{messageData.preamble}</p>
                  <br />
                  {messageData.artifacts.map((artifact) => (
                    <p key={artifact.id + artifact.footnoteNumber}>
                      <Tooltip
                        overlayStyle={{
                          maxHeight: '500px',
                          maxWidth: '500px',
                          overflowY: 'auto',
                          width: 'fit-content',
                        }}
                        placement="right"
                        // display the source text when hovering over the icon, including the original line breaks
                        title={
                          <pre
                            style={{
                              overflowWrap: 'break-word',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                            }}
                          >
                            {artifact.content}
                          </pre>
                        }
                        // title={artifact.content}
                      >
                        {artifact.footnoteNumber} {artifact.name}{' '}
                        <a
                          href={artifact.source}
                          key={artifact.id}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <ExportOutlined />
                        </a>
                      </Tooltip>
                    </p>
                  ))}
                  <Divider />
                  <p>{messageData.content}</p>
                </div>
              ) : (
                <p>{messageData.content}</p>
              )}
            </>
          }
          datetime={messageData.time}
          key={messageData.content}
          style={{
            alignContent: messageData.author === 'You' ? 'end' : 'start',
            alignSelf: messageData.author === 'You' ? 'flex-end' : 'flex-start',
            backgroundColor:
              messageData.author === 'You' ? 'var(--primary-color)' : '#f0f0f0',
            border: '1px solid #f0f0f0',
            borderRadius: '5px',
            padding: '0 10px',
            width: 'fit-content',
          }}
        />
      </>
    );
  }
);

export default ChatMessage;
