import {CloseCircleOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Checkbox} from 'antd';
import {useFetchAggregateReportsListQuery} from 'api/reportsSlice';
import RichTextEditor from 'components/dataPortal/RichTextEditor';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import React, {useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';

const widgetTypes = [
  // {value: 'chart', label: 'Superset Chart'},
  {value: 'text', label: 'Text'},
  {value: 'table', label: 'Data Report'},
  {value: 'google', label: 'Google File'},
  {value: 'pdf', label: 'PDF'},
  {value: 'dashboard', label: 'Superset Dashboard'},
];

const Container = styled.div`
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  &:hover {
    border-color: #aaa;
  }
`;

const WidgetForm = ({deleteWidget, idx, setWidget, widget}) => {
  const [visible, setVisible] = useState(false);

  // TODO: consider using the new endpoint fetchAggregateReportsTree
  const {data: reports} = useFetchAggregateReportsListQuery(true);

  const setWidgetExtraFields = (value, key) => {
    if (!widget.extra) {
      setWidget({[key]: value}, 'extra');
    } else {
      const newExtra = {
        ...widget.extra,
        [key]: value,
      };
      setWidget(newExtra, 'extra');
    }
  };

  return (
    <Draggable draggableId={`widget-${widget.id}`} index={idx}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          key={widget.id}
          className="flex-row"
        >
          <CloseCircleOutlined
            onClick={(e) => {
              e.stopPropagation();
              deleteWidget(widget.id);
            }}
            style={{
              margin: '8px 0',
            }}
            title="Remove Widget"
          />
          <div className="flex-row" style={{flexGrow: 1}}>
            <SelectOptions
              allowClear={false}
              label="Type"
              onChange={(val) => setWidget(val, 'type')}
              options={widgetTypes}
              required={true}
              style={{width: '180px'}}
              value={widget.type}
            />
            <div style={{flexGrow: 1}} className="flex-column">
              {widget.type === 'table' && (
                <SelectOptions
                  label="Data Report"
                  onChange={(val) => setWidget(val, 'source')}
                  options={reports
                    ?.filter((r) => r.published)
                    .map((r) => ({
                      value: r.slug,
                      label: r.title,
                    }))}
                  required={true}
                  value={
                    widget.source
                      ? reports?.find((r) => r.slug === widget.source)?.title
                      : null
                  }
                />
              )}
              {widget.type === 'dashboard' && (
                <div style={{flexGrow: 1}} className="flex-column">
                  <InputField
                    label="ID"
                    onChange={(e) => setWidget(e.target.value, 'title')}
                    required={true}
                    value={widget.title}
                  />
                  <InputField
                    label="Embed ID"
                    onChange={(e) => setWidget(e.target.value, 'source')}
                    required={true}
                    value={widget.source}
                  />
                </div>
              )}
              {widget.type === 'google' && (
                <>
                  <InputField
                    label="Google File URL"
                    onChange={(e) => setWidget(e.target.value, 'source')}
                    required={true}
                    value={widget.source}
                  />
                  <InputField
                    label="Title (optional)"
                    onChange={(e) => setWidget(e.target.value, 'title')}
                    value={widget.title}
                    width="100px"
                  />
                  <span>Display File Data:</span>
                  <div className="flex-row" style={{gap: '5px'}}>
                    <Checkbox
                      checked={widget.extra?.owners}
                      onChange={(e) =>
                        setWidgetExtraFields(e.target.checked, 'owners')
                      }
                    >
                      Owners
                    </Checkbox>
                    <Checkbox
                      checked={widget.extra?.modifiedTime}
                      onChange={(e) =>
                        setWidgetExtraFields(e.target.checked, 'modifiedTime')
                      }
                    >
                      Last Modified
                    </Checkbox>
                    <Checkbox
                      checked={widget.extra?.lastModifyingUser}
                      onChange={(e) =>
                        setWidgetExtraFields(
                          e.target.checked,
                          'lastModifyingUser'
                        )
                      }
                    >
                      Editor
                    </Checkbox>
                  </div>
                </>
              )}
              {widget.type === 'pdf' && (
                <>
                  <InputField
                    label="URL"
                    onChange={(e) => setWidget(e.target.value, 'source')}
                    required={true}
                    value={widget.source}
                  />
                  <InputField
                    label="Title (optional)"
                    onChange={(e) => setWidget(e.target.value, 'title')}
                    value={widget.title}
                  />
                </>
              )}
            </div>
          </div>
          {widget.type === 'text' && (
            <>
              <Button onClick={() => setVisible(!visible)} size="large">
                Edit
                <RightOutlined />
              </Button>
              <div
                style={{
                  marginLeft: '24px',
                  position: 'relative',
                }}
              >
                <label
                  style={{
                    backgroundColor: 'white',
                    color: 'rgba(0, 0, 0, 0.35)',
                    fontSize: 10,
                    left: 6,
                    padding: '0 5px',
                    position: 'absolute',
                    top: -8,
                    zIndex: 2,
                  }}
                >
                  Preview
                </label>
                <div
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '2px',
                    overflow: 'auto',
                    padding: '10px',
                    position: 'relative',
                    width: '491px',
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{__html: widget.content}}
                    style={{
                      maxHeight: '300px',
                      width: '100%',
                      zoom: 0.7,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <RichTextEditor
            initialValue={widget.content}
            onChange={(content) => setWidget(content, 'content', false)}
            setVisible={setVisible}
            visible={visible}
          />
        </Container>
      )}
    </Draggable>
  );
};

export default WidgetForm;
