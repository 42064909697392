import {ExportOutlined} from '@ant-design/icons';
import useGoogleAuth from 'hooks/useGoogleAuth';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {tsToNy} from 'utils/helpers';

const DocViewer = ({docLink, widget = {}}) => {
  const [metadata, setMetadata] = useState(null);
  const {initializeGoogleAuth} = useGoogleAuth();

  const accessToken = useSelector((state) => state.googleAuth.accessToken);

  const docId = docLink.split('/')[5];

  const setGoogleSource = (val) => {
    try {
      const url = new URL(val);
      const path = url.pathname.split('/');
      const type = path[1];
      const id = path[3];

      if (type === 'document') {
        return `https://docs.google.com/document/d/${id}/preview`;
      } else if (type === 'spreadsheets') {
        return `https://docs.google.com/spreadsheets/d/${id}/preview`;
      } else if (type === 'presentation') {
        return `https://docs.google.com/presentation/d/${id}/preview`;
      } else if (type === 'file') {
        return `https://drive.google.com/file/d/${id}/preview`;
      }
      return val;
    } catch (error) {
      console.error('Error parsing google url', error);
      return val;
    }
  };

  const fetchMetadata = useCallback(
    async (token) => {
      const fields =
        'name,owners(displayName),modifiedTime,lastModifyingUser(displayName)';
      try {
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files/${docId}?fields=${fields}`,
          {
            headers: {Authorization: `Bearer ${token}`},
          }
        );
        if (!response.ok) {
          console.error('Error fetching metadata', response);
          return;
        }
        const data = await response.json();
        setMetadata(data);
      } catch (err) {
        console.error('Error fetching metadata', err);
      }
    },
    [docId]
  );

  useEffect(() => {
    // If widget is not a defined as a google doc, or none of the extra fields are selected, return
    if (
      widget?.type !== 'google' ||
      !(
        widget?.extra?.owners ||
        widget?.extra?.modifiedTime ||
        widget?.extra?.lastModifyingUser
      )
    ) {
      return;
    }
    if (!accessToken) {
      initializeGoogleAuth();
    } else {
      fetchMetadata(accessToken);
    }
  }, [accessToken, widget]);

  return (
    <div>
      <h3>
        {widget.title ? widget.title : metadata?.name}{' '}
        <Link to={docLink} target="_blank" rel="noreferrer">
          <ExportOutlined />
        </Link>
      </h3>
      {metadata && (
        <>
          {widget?.extra?.owners && metadata.owners && (
            <div>
              Owners:{' '}
              {metadata.owners?.map((owner) => owner?.displayName).join(', ')}
            </div>
          )}
          {(widget?.extra?.lastModifyingUser ||
            widget?.extra?.modifiedTime) && (
            <div>
              Last edited:{' '}
              {widget?.extra?.lastModifyingUser &&
                `${metadata.lastModifyingUser?.displayName}, `}
              {widget?.extra?.modifiedTime &&
                `${tsToNy(metadata.modifiedTime)}`}
            </div>
          )}
        </>
      )}
      <iframe
        frameBorder="0"
        height="800"
        src={setGoogleSource(docLink)}
        style={{border: '1px solid #939598'}}
        width="100%"
      />
    </div>
  );
};

export default DocViewer;
