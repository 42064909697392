import {SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import React from 'react';

const SortButton = ({column, setColumn}) => {
  const sortOptions = ['ascend', 'descend', null];

  const onClick = (e) => {
    e.stopPropagation();
    const idx = sortOptions.indexOf(column?.sort);
    const newSort = sortOptions[(idx + 1) % sortOptions.length];
    setColumn(newSort, 'sort');
  };

  return (
    // cycle between sort options (ascend, descend, undefined), and set sort_priority
    <>
      <Tooltip
        title={
          !column.sort
            ? 'Sort'
            : column.sort === 'ascend'
              ? 'Sort Descending'
              : 'Remove Sort'
        }
      >
        <Button
          onClick={onClick}
          size="large"
          style={{
            border: column.sort
              ? '1px solid var(--primary-color)'
              : '1px solid #ddd',
          }}
        >
          {column.sort === 'ascend' ? (
            <SortAscendingOutlined style={{color: 'var(--primary-color)'}} />
          ) : column.sort === 'descend' ? (
            <SortDescendingOutlined style={{color: 'var(--primary-color)'}} />
          ) : (
            <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>&#8645;</span>
          )}
        </Button>
      </Tooltip>
    </>
  );
};

export default SortButton;
