import Auth from 'Auth';
import {Breadcrumb} from 'antd';
import {useFetchSeedDataQuery} from 'api/seedsSlice';
import HelpModal from 'components/genericComponents/HelpModal';
import SeedForm from 'components/seeds/SeedForm';
import TableEditor from 'components/seeds/TableEditor';
import PageLeaveWarning from 'hooks/PageLeaveWarning';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {selectIsFormEdited} from 'store/formSlice';

const SeedEditorPage = () => {
  const location = useLocation();
  const seedId = location.pathname.split('/')[2];
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;
  const params = new URLSearchParams(location.search);

  const isFormDirty = useSelector(
    selectIsFormEdited(`source_manager_${seedId}`)
  );
  const isTableDirty = useSelector(
    selectIsFormEdited(`table_editor_${seedId}`)
  );

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data: seedData} = useFetchSeedDataQuery(
    {
      name: seedId,
      version,
    },
    {
      skip: !seedId,
    }
  );

  return (
    <div style={{position: 'relative'}}>
      <HelpModal
        title="How to use the Track-Managed Sources Editor"
        content={
          <>
            <p>Double-click a cell or start typing to open a dropdown.</p>

            {seedData?.metadata?.enable_autoload && (
              <p>
                Click <strong>Load New Entries</strong> to fetch any new rows
                from the source table. Existing rows won’t be duplicated or
                overwritten.
              </p>
            )}

            <p>
              To delete data: Selecting one or more rows and pressing{' '}
              <strong>Delete</strong> will clear only the editable columns. To
              remove entire rows, right-click after selecting and choose{' '}
              <strong>Remove Row/s</strong>.
            </p>

            <p>
              Use <strong>Check Statuses</strong> to review your changes before
              saving.
            </p>

            <p>
              Always click <strong>Save Draft</strong> before selecting{' '}
              <strong>Finalize Changes</strong>.
            </p>

            <p>
              To sort by multiple columns, hold <strong>Ctrl</strong> (or{' '}
              <strong>Cmd</strong> on Mac) while clicking column headers.
            </p>

            <p>
              For more details, see the{' '}
              <a
                href="https://app.getguru.com/card/TG9MXa6c/TRACK-Source-Manager-Overview"
                target="_blank"
                rel="noreferrer"
              >
                TRACK Source Manager Overview
              </a>
              .
            </p>
          </>
        }
      />
      <PageLeaveWarning
        message="You have unsaved changes. Are you sure you want to leave?"
        isDirty={isFormDirty || isTableDirty}
      />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/source_manager/${editorMode ? 'editor' : ''}`}>
            Source Manager
          </Link>
        </Breadcrumb.Item>
        {seedData?.metadata?.path?.map((path) => (
          <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
        ))}
        <Breadcrumb.Item>
          <Link to={`/source_manager/${seedId}`}>{seedId}</Link>
        </Breadcrumb.Item>
        {editorMode && <Breadcrumb.Item>Editor</Breadcrumb.Item>}
      </Breadcrumb>
      <h1>Track-Managed Sources Editor</h1>
      <h1>{seedId || 'Playground'}</h1>
      <div className="flex-column">
        <SeedForm />
        <TableEditor />
      </div>
    </div>
  );
};

export default SeedEditorPage;
