import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'utils/errorHandler';

const reportsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchReportMeta: builder.query({
      query: ({slug, version}) =>
        `/data_reports/metadata/${slug}?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {
          type: 'ReportMeta',
          id: `${arg.slug}`,
        },
      ],
    }),
    fetchReport: builder.query({
      query: ({slug, version, queryUrl}) =>
        `/data_reports/report/${slug}?version=${version}${queryUrl ? `&${queryUrl}` : ''}`,
      transformResponse: (response) => {
        return {
          error: response.errors,
          report: response.report,
          sql: response.sql_statement,
          total: response.row_count,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'Report', id: `${arg.slug}`},
      ],
    }),
    fetchAggregateReportsList: builder.query({
      query: (published) => `/data_reports/reports?published=${published}`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['ReportsList'],
    }),
    fetchAggregateReportsTree: builder.query({
      query: (version) => `/data_reports/reports_tree?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'ReportsTree', id: arg}],
    }),
    fetchReportSources: builder.query({
      query: () => `/data_reports/sources`,
      transformResponse: (response) => {
        return response.sources?.sort();
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['ReportSources'],
    }),
    fetchReportSourceColumns: builder.query({
      query: (source) => `/data_reports/sources/${source}`,
      transformResponse: (response) => {
        return response.columns;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'ReportSourceColumns', id: arg},
      ],
    }),
    saveReport: builder.mutation({
      query: (report) => ({
        url: `/data_reports/editor/`,
        method: 'POST',
        body: report,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['ReportMeta', 'Report', 'ReportsTree'],
    }),
    publishReport: builder.mutation({
      query: (report) => ({
        url: `/data_reports/editor/${report.slug}/?version=${report.version}`,
        method: 'PUT',
        body: report,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['ReportMeta', 'Report', 'ReportsTree'],
    }),
    deleteReport: builder.mutation({
      query: ({slug, version}) => ({
        url: `/data_reports/editor/${slug}/${version ? `?version=${version}` : ''}`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['ReportMeta', 'Report', 'ReportsTree'],
    }),
  }),
});

export const {
  useFetchReportMetaQuery,
  useFetchReportQuery,
  useFetchAggregateReportsListQuery,
  useFetchAggregateReportsTreeQuery,
  useFetchReportSourcesQuery,
  useFetchReportSourceColumnsQuery,
  useSaveReportMutation,
  usePublishReportMutation,
  useDeleteReportMutation,
} = reportsSlice;
