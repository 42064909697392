import {createSelector, createSlice} from '@reduxjs/toolkit';
import isEqual from 'fast-deep-equal';

const initialState = {
  forms: {},
  originalData: {},
};

const updateNestedValue = (obj, path, value) => {
  const [current, ...rest] = path;

  if (rest.length === 0) {
    if (Array.isArray(obj)) {
      const index = parseInt(current, 10);
      if (obj[index] === value) return obj; // Skip update if same
      const newArray = [...obj];
      newArray[index] = value;
      return newArray;
    } else {
      if (obj?.[current] === value) return obj; // Skip update if same
      return {...obj, [current]: value};
    }
  }

  if (Array.isArray(obj)) {
    const index = parseInt(current, 10);
    if (isEqual(obj[index], value)) return obj; // Skip update if same
    const newArray = [...obj];
    newArray[index] = updateNestedValue(obj[index], rest, value);
    return newArray;
  } else {
    if (isEqual(obj?.[current], value)) return obj; // Skip update if same
    return {
      ...obj,
      [current]: updateNestedValue(obj?.[current] || {}, rest, value),
    };
  }
};

export const formSlice = createSlice({
  initialState,
  name: 'form',
  reducers: {
    setFormData(state, action) {
      const {id, data} = action.payload;
      state.forms[id] = {...data};
      state.originalData[id] = {...data}; // Keep a copy of the original data
    },
    updateFormField(state, action) {
      const {id, field, value} = action.payload;
      if (field.includes('.')) {
        const path = field.split('.');
        const topField = path[0];

        // Return a new state for deep nesting
        return {
          ...state,
          forms: {
            ...state.forms,
            [id]: {
              ...state.forms[id],
              [topField]: updateNestedValue(
                state.forms[id]?.[topField],
                path.slice(1),
                value
              ),
            },
          },
        };
      } else {
        // Use Immer for simple top-level updates
        if (state.forms[id]) {
          state.forms[id][field] = value;
        } else {
          console.error(`Form with id ${id} not found`);
        }
      }
    },
    updateOriginalField(state, action) {
      const {id, field, value} = action.payload;
      if (state.originalData[id]) {
        state.originalData[id][field] = value;
      } else {
        console.error(`Form with id ${id} not found`);
      }
    },
    resetForm(state, action) {
      const id = action.payload;
      if (state.originalData[id]) {
        state.forms[id] = {...state.originalData[id]};
      } else {
        console.warn(`Original data for form "${id}" does not exist.`);
      }
    },
  },
});

export const {setFormData, updateFormField, updateOriginalField, resetForm} =
  formSlice.actions;

export const selectFormState = (state, formId) => state.form.forms[formId];

export const selectOriginalFormData = (state, formId) =>
  state.form.originalData[formId];

export const selectIsFormEdited = (formId) =>
  createSelector(
    [
      (state) => selectFormState(state, formId),
      (state) => selectOriginalFormData(state, formId),
    ],
    (formData, originalData) => {
      if (!formData || !originalData) return false;
      return !isEqual(formData, originalData);
    }
  );

// selector for if any version of the form is published
export const selectHasPublishedVersion = (formId) =>
  createSelector([(state) => selectFormState(state, formId)], (formState) => {
    if (!formState) return false;
    return formState.version_history?.some((version) => version.published);
  });

export const selectVersionsCount = (formId) =>
  createSelector([(state) => selectFormState(state, formId)], (formState) => {
    if (!formState) return 0;
    return formState.version_history?.length || 0;
  });

export default formSlice.reducer;
