import {Button, Comment, Modal, Tag} from 'antd';
import moment from 'moment';
import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const VersionItem = ({
  currentVersion,
  handleDelete,
  handlePublish,
  version,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname.split('/')[1];
  const slug = location.pathname.split('/')[2];

  const [confirmAction, setConfirmAction] = useState(null);

  const confirmationConfigs = {
    deleteVersion: {
      title: 'Delete This Version',
      content:
        'Are you sure you want to delete this version? This action cannot be undone.',
      action: () => {
        handleDelete(currentVersion);
        // if the version being deleted is the current version, redirect to the latest version
        if (currentVersion === version.version) {
          navigate(`/${pathname}/${slug}/editor`);
        }
      },
    },
    publish: {
      title: 'Publish',
      content: 'Are you sure you want to publish this versions?',
      action: () => handlePublish(true),
    },
    unpublish: {
      title: 'Publish',
      content:
        'Are you sure you want to unpublish this versions? Non-editor users will no longer have access.',
      action: () => handlePublish(false),
    },
  };

  const showConfirmation = (actionKey) => {
    setConfirmAction(actionKey);
  };

  const handleConfirm = async () => {
    if (confirmAction && confirmationConfigs[confirmAction]) {
      try {
        await confirmationConfigs[confirmAction].action();
        setConfirmAction(null);
      } catch (error) {
        // Handle error if needed
      }
    }
  };

  return (
    <>
      <Comment
        style={{
          borderTop: '1px solid #f0f0f0',
        }}
        actions={[
          <Button
            disabled={version.version === currentVersion}
            key="edit"
            onClick={() =>
              navigate(`/${pathname}/${slug}/editor?version=${version.version}`)
            }
            size="small"
            type="link"
          >
            View
          </Button>,
          <Button
            danger
            disabled={version.published}
            onClick={() => showConfirmation('deleteVersion')}
            size="small"
            type="link"
          >
            Delete
          </Button>,
          version.published ? (
            <Button
              danger
              onClick={() => showConfirmation('unpublish')}
              size="small"
              type="link"
            >
              Unpublish
            </Button>
          ) : (
            <Button
              onClick={() => showConfirmation('publish')}
              size="small"
              type="link"
            >
              Publish this Version
            </Button>
          ),
        ]}
        author={version.author ?? 'Unknown'}
        avatar={null}
        content={
          <div>
            <strong>{version.title}</strong>
            {version.version === currentVersion && (
              <Tag color="blue" style={{marginLeft: 10}}>
                Current
              </Tag>
            )}
            {version.published && (
              <Tag color="green" style={{marginLeft: 10}}>
                Published
              </Tag>
            )}
          </div>
        }
        datetime={moment(version.updated_at).format('MMMM Do YYYY, h:mm a')}
        key={version.version}
      />
      <Modal
        cancelText="Cancel"
        okText="Confirm"
        onCancel={() => setConfirmAction(null)}
        onOk={handleConfirm}
        open={!!confirmAction}
        title={confirmAction ? confirmationConfigs[confirmAction].title : ''}
      >
        {confirmAction && confirmationConfigs[confirmAction].content}
      </Modal>
    </>
  );
};

export default VersionItem;
