import Auth from 'Auth';
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Modal,
  Spin,
  Tooltip,
  message,
} from 'antd';
import {
  useDeletePageMutation,
  useFetchPageQuery,
  usePublishPageMutation,
  useSavePageMutation,
} from 'api/cmsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import WidgetForm from 'components/dataPortal/WidgetForm';
import EditorButton from 'components/genericComponents/EditorButton';
import InputField from 'components/genericComponents/Input';
import PublishButton from 'components/genericComponents/PublishButton';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import React, {useEffect, useMemo, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {selectFormState, setFormData, updateFormField} from 'store/formSlice';
import {store} from 'store/store';
import styled from 'styled-components';
import {generateUniqueId} from 'utils/helpers';

const {Panel} = Collapse;

const WidgetList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

const PageForm = ({searchData}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageSlug = location.pathname.split('/')[2] || 'new';

  const dispatch = useDispatch();
  const formState = useSelector((state) =>
    selectFormState(state, `data_portal_${pageSlug}`)
  );

  const params = new URLSearchParams(location.search);

  const [drawerVisible, setDrawerVisible] = useState(pageSlug === 'new');
  const [modalOpen, setModalOpen] = useState(false);
  const [newPageTitle, setNewPageTitle] = useState('');

  const editorMode = useMemo(() => {
    return (
      (location.pathname.endsWith('/editor') ||
        location.pathname.endsWith('_chat')) &&
      Auth.permissions.access_to_data_portal_editor &&
      formState?.author !== 'AI'
    );
  }, [location.pathname, formState?.author]);

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data: tags} = useFetchTagsQuery();
  const {data: categories} = useFetchCategoriesQuery();
  const {data, isLoading} = useFetchPageQuery(
    {
      slug: pageSlug,
      version,
    },
    {skip: !pageSlug || pageSlug === 'new'}
  );

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createTag] = useCreateOrUpdateTagMutation();
  const [deletePage] = useDeletePageMutation();
  const [publishPage] = usePublishPageMutation();
  const [savePage] = useSavePageMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    dispatch(setFormData({id: `data_portal_${pageSlug}`, data: data || {}}));
    if (searchData) {
      dispatch(
        updateFormField({
          id: `data_portal_${pageSlug}`,
          field: 'widgets',
          value: searchData,
        })
      );
    }
  }, [data]);

  const handleAddWidget = () => {
    const widgets = formState?.widgets || [];
    const newWidget = {
      id: generateUniqueId(widgets, 'int'),
      page: formState?.id,
    };
    handlePageChanged([...widgets, newWidget], 'widgets');
  };

  const handleDeleteWidget = (widgetId) => {
    const updatedWidgets = formState?.widgets.filter(
      (widget) => widget.id !== widgetId
    );
    handlePageChanged(updatedWidgets, 'widgets');
  };

  const handleWidgetChanged = (value, field, widgetId) => {
    const updatedWidgets = [...formState?.widgets];
    const widgetIndex = updatedWidgets.findIndex((w) => w.id === widgetId);
    updatedWidgets[widgetIndex] = {
      ...updatedWidgets[widgetIndex],
      [field]: value,
    };
    if (field === 'type') {
      updatedWidgets[widgetIndex].extra = {
        owners: true,
        lastModifyingUser: true,
        modifiedTime: true,
      };
    }
    handlePageChanged(updatedWidgets, 'widgets');
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formState.widgets);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    handlePageChanged(items, 'widgets');
  };

  const handlePageChanged = (value, type) => {
    dispatch(
      updateFormField({id: `data_portal_${pageSlug}`, field: type, value})
    );
  };

  const handleSave = (publish) => {
    message.loading({content: 'Saving...', key: 'save'});
    const currentFormState =
      store.getState().form.forms[`data_portal_${pageSlug}`];
    const page = {
      ...currentFormState,
      published: publish,
      // delete local_id from widgets
      widgets: formState.widgets?.map((w) => {
        const temp = {...w};
        delete temp.local_id;
        return temp;
      }),
    };
    if (pageSlug === 'new') {
      dispatch(setFormData({id: `data_portal_new`, data: {}}));
    }
    savePage(page)
      .unwrap()
      .then((res) => {
        dispatch(
          setFormData({
            id: `data_portal_${res.slug}`,
            data: {...formState, published: publish},
          })
        );
        if (pageSlug === 'new') {
          navigate(`/data_portal/${res.slug}/editor`);
        } else {
          const url = new URL(window.location.href);
          url.searchParams.set('version', 'latest');
          window.history.pushState({}, '', url);
        }
        message.success({content: 'Page saved successfully', key: 'save'});
      });
  };

  const handleDelete = (version) => {
    message.loading({content: 'Deleting...', key: 'delete'});
    if (pageSlug) {
      deletePage({
        slug: pageSlug,
        version,
      })
        .unwrap()
        .then(() => {
          message.success({
            content: 'Page deleted successfully',
            key: 'delete',
          });
        });
    }
    navigate(
      version ? `/data_portal/${pageSlug}/editor` : `/data_portal/editor`
    );
  };

  const handleClone = () => {
    if (!pageSlug) {
      message.error('Cannot clone a new page. Save this page first.');
    }
    message.loading({content: 'Cloning...', key: 'clone'});
    const currentFormState =
      store.getState().form.forms[`data_portal_${pageSlug}`];
    const page = {
      category: currentFormState?.category,
      tags: currentFormState?.tags,
      title: newPageTitle,
      widgets: currentFormState.widgets?.map((w) => {
        const temp = {...w};
        delete temp.local_id;
        return temp;
      }),
    };
    savePage(page)
      .unwrap()
      .then((res) => {
        setModalOpen(false);
        navigate(`/data_portal/${res.slug}/editor`);
        message.success({content: 'Page cloned successfully', key: 'clone'});
      });
  };

  const handlePublish = (publish, version) => {
    message.loading({content: 'Publishing...', key: 'publish'});
    if (formState.widgets?.length === 0 && publish) {
      message.error('Cannot publish page without widgets');
      return;
    }
    const currentFormState =
      store.getState().form.forms[`data_portal_${pageSlug}`];
    publishPage({
      published: publish,
      slug: pageSlug,
      version: version || currentFormState.version,
    })
      .unwrap()
      .then(() => {
        dispatch(
          setFormData({
            id: `data_portal_${pageSlug}`,
            data: {...currentFormState, published: publish},
          })
        );
        message.success({
          content: publish
            ? 'Page published successfully'
            : 'Page unpublished successfully',
          key: 'publish',
        });
      });
  };

  return (
    <>
      <Drawer
        closable={false}
        extra={
          <PublishButton
            formId={`data_portal_${pageSlug}`}
            handleDelete={handleDelete}
            handlePublish={handlePublish}
            handleSave={handleSave}
            isLoading={isLoading}
            isValid={formState?.title && formState?.widgets?.length > 0}
          />
        }
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          minHeight: 'calc(100% - 64px)',
          overflowY: 'auto',
          position: 'fixed',
        }}
        title={<h3>Page Editor</h3>}
        width={600}
      >
        <Spin spinning={isLoading}>
          <div className="flex-column">
            <InputField
              label="Title"
              onChange={(e) => handlePageChanged(e.target.value, 'title')}
              required={true}
              style={{width: '300px'}}
              value={formState?.title}
            />
            <SelectOptions
              label="Category"
              onAdd={(name) => {
                createOrUpdateCategory({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handlePageChanged(res, 'category');
                  });
              }}
              onChange={(value) =>
                handlePageChanged(
                  {
                    id: value.id,
                    name: value.title,
                    parent: value.parent,
                  },
                  'category'
                )
              }
              options={categories?.children}
              tree={true}
              value={formState?.category?.id}
            />
            <SelectOptions
              label="Tags"
              onAdd={(name) => {
                createTag({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handlePageChanged(
                      [...(formState?.tags || []), res],
                      'tags'
                    );
                  });
              }}
              onChange={(value) =>
                handlePageChanged(
                  value.map((v) => tags?.find((c) => c.id === v) || null),
                  'tags'
                )
              }
              options={tags?.map((c) => ({
                color: c.color,
                label: c.name,
                value: c.id,
              }))}
              mode="tags"
              multiple
              style={{width: '100%'}}
              value={formState?.tags?.map((t) => t.id)}
            />
            {Auth.permissions.access_to_search && (
              <div>
                <Checkbox
                  checked={formState?.is_search_context}
                  onChange={(e) =>
                    handlePageChanged(e.target.checked, 'is_search_context')
                  }
                >
                  Include in search context
                </Checkbox>
                <Checkbox
                  checked={formState?.is_spotlight}
                  onChange={(e) =>
                    handlePageChanged(e.target.checked, 'is_spotlight')
                  }
                >
                  Set as Editor's Pick
                </Checkbox>
              </div>
            )}
          </div>
          <Button
            onClick={() => setModalOpen(true)}
            style={{marginTop: '10px', padding: 0}}
            type="link"
          >
            Duplicate Page
          </Button>
          <Divider />
          <Alert
            description={
              <div>
                <h4>Superset:</h4>
                <ol>
                  <li>
                    <b>Dashboard ID</b>: copy the number after{' '}
                    <b>'/dashboard/'</b> in the URL of your dashboard.
                  </li>
                  <li>
                    <b>Embed ID</b>: click <b>'Embed dashboard'</b> and copy the
                    ID.
                  </li>
                  <li>
                    Paste both IDs into the corresponding fields in the SuperSet
                    Dashboard's widget.
                  </li>
                </ol>
                <h4>Sharepoint (PDF):</h4>
                <ol>
                  <li>
                    In the sharepoint document, click on <b>'...'</b> and then
                    on <b>'Embed'</b>.
                  </li>
                  <li>
                    Copy the URL from the <b>'src'</b> attribute in the iframe
                    and paste to the <b>Source</b> field.
                  </li>
                  <li>
                    Optionally, add a title to the <b>Title</b> field.
                  </li>
                </ol>
                <h4>Google:</h4>
                <ol>
                  <li>
                    At the top right corner, click <b>'Share'</b>. Ensure{' '}
                    <b>General access</b> is set to either:
                    <ul>
                      <li>Anyone with the link</li>
                      <li>Uncommon Schools</li>
                    </ul>
                  </li>
                  <li>
                    Copy the Google file's URL and paste the URL into the Google
                    File's widget.
                  </li>
                </ol>
              </div>
            }
            type="success"
            style={{marginBottom: '20px'}}
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="widgets">
              {(provided) => (
                <WidgetList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {formState?.widgets?.map((widget, idx) => (
                    <WidgetForm
                      deleteWidget={handleDeleteWidget}
                      idx={idx}
                      key={widget.id}
                      setWidget={(value, field) =>
                        handleWidgetChanged(value, field, widget.id)
                      }
                      widget={widget}
                    />
                  ))}
                  {provided.placeholder}
                </WidgetList>
              )}
            </Droppable>
          </DragDropContext>
          <div>
            <Button
              onClick={handleAddWidget}
              style={{marginTop: '10px'}}
              type="primary"
            >
              Add Widget
            </Button>
          </div>
          <Divider />
          {formState?.version_history?.length > 1 ? (
            <Collapse
              expandIconPosition="end"
              ghost
              style={{marginTop: '20px'}}
            >
              <Panel header="Version History" key="1">
                {formState?.version_history?.map((version) => {
                  return (
                    <VersionItem
                      currentVersion={formState.version}
                      handleDelete={() => handleDelete(version.version)}
                      handlePublish={(publish) =>
                        handlePublish(publish, version.version)
                      }
                      key={version.version}
                      version={version}
                    />
                  );
                })}
              </Panel>
            </Collapse>
          ) : null}
          <Modal
            onCancel={() => setModalOpen(false)}
            onOk={handleClone}
            open={modalOpen}
            title="Duplicate Page"
          >
            <InputField
              label="New Page Title"
              onChange={(e) => setNewPageTitle(e.target.value)}
              required={true}
              value={newPageTitle}
            />
          </Modal>
        </Spin>
      </Drawer>
      {formState?.author !== 'AI' ? (
        <EditorButton
          drawerVisible={drawerVisible}
          formId={`data_portal_${pageSlug}`}
          key={pageSlug + editorMode}
          path={`/data_portal/${pageSlug}`}
          setDrawerVisible={setDrawerVisible}
        />
      ) : (
        <div style={{position: 'fixed', left: '0', top: '15%', zIndex: 1000}}>
          <Tooltip
            title="This page was generated by AI and cannot be edited."
            placement="right"
          >
            <Alert type="info" showIcon />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default PageForm;
