import Auth from 'Auth';
import {useFetchSeedsQuery} from 'api/seedsSlice';
import Catalog from 'components/genericComponents/Catalog';
import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

const SeedsOverviewPage = () => {
  const location = useLocation();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;
  const params = new URLSearchParams(location.search);

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data: seeds, isLoading: isSeedsLoading} = useFetchSeedsQuery(version);

  const extraTags = [
    {
      color: 'red',
      id: 'draft',
      label: 'Draft',
      name: 'Draft',
      value: 'draft',
    },
    {
      color: 'magenta',
      id: 'data_changed',
      label: 'Unpublished Data Changes',
      name: 'Unpublished Data Changes',
      value: 'data_changed',
    },
    {
      color: 'orange',
      id: 'schema_changed',
      label: 'Unpublished Schema Changes',
      name: 'Unpublished Schema Changes',
      value: 'schema_changed',
    },
  ];

  return (
    <div>
      <h1>Track-Managed Sources</h1>
      <Catalog
        editorPermission={Auth.permissions.access_to_source_manager_editor}
        extraTags={extraTags}
        loading={isSeedsLoading}
        items={seeds}
        path="source_manager"
      />
    </div>
  );
};

export default SeedsOverviewPage;
