import Auth from 'Auth';
import {useFetchAggregateReportsTreeQuery} from 'api/reportsSlice';
import Catalog from 'components/genericComponents/Catalog';
import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

const DataReportOverviewPage = () => {
  const location = useLocation();
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;
  const params = new URLSearchParams(location.search);

  const version = useMemo(() => {
    if (!editorMode) {
      return 'published';
    } else if (params.get('version')) {
      return params.get('version');
    } else {
      return 'latest';
    }
  }, [params.get('version'), editorMode]);

  const {data: reports, isLoading} = useFetchAggregateReportsTreeQuery(version);

  const extraTags = [
    {
      color: 'red',
      id: 'draft',
      label: 'Draft',
      name: 'Draft',
      value: 'draft',
    },
    {
      color: 'orange',
      id: 'schema_changed',
      label: 'Unpublished Changes',
      name: 'Unpublished Changes',
      value: 'schema_changed',
    },
  ];

  return (
    <div>
      <h1>Data Reports</h1>
      <Catalog
        buttons={[
          {
            label: 'Create New Report',
            href: `/data_report/new/editor`,
          },
        ]}
        editorPermission={Auth.permissions.access_to_data_reports_editor}
        extraTags={extraTags}
        loading={isLoading}
        items={reports}
        path="data_report"
      />
    </div>
  );
};

export default DataReportOverviewPage;
