import React, {useEffect, useState} from 'react';

const RichTextDisplay = ({value}) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    if (value) {
      setHtml(value);
    }
  }, [value]);

  return (
    <div
      style={{
        width: '100%',
        height: 'minContent',
        overflow: 'auto',
        padding: '10px',
      }}
      dangerouslySetInnerHTML={{__html: html}}
    />
  );
};

export default RichTextDisplay;
