import {LeftOutlined} from '@ant-design/icons';
import {Button, Checkbox, Collapse, Popconfirm, Spin} from 'antd';
import {useFetchSeedsQuery} from 'api/seedsSlice';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const SeedChangesPage = () => {
  const {data: seeds, isSuccess, isLoading} = useFetchSeedsQuery();

  const [selectedSeeds, setSelectedSeeds] = useState([]);
  const [diffs, setDiffs] = useState([]);
  const [reviewed, setReviewed] = useState([]);

  const checkIfAllReviewed = () => {
    const allReviewed = diffs.every((item) => reviewed.includes(item));
    return allReviewed;
  };

  const genExtra = (key) => (
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        if (e.target.checked) {
          setSelectedSeeds([...selectedSeeds, key]);
        } else {
          setSelectedSeeds(selectedSeeds.filter((seed) => seed !== key));
        }
      }}
      checked={selectedSeeds.includes(key)}
    >
      Use in Test Run
    </Checkbox>
  );

  const genExtraDiffs = (key) => (
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        if (e.target.checked) {
          setReviewed([...reviewed, key]);
        } else {
          setReviewed(reviewed.filter((seed) => seed !== key));
        }
      }}
      checked={reviewed.includes(key)}
    >
      Reviewed
    </Checkbox>
  );

  return (
    <div>
      <Link to={`/seeds`}>
        <LeftOutlined />
        Back to Sources
      </Link>
      <div
        className="flex-column"
        style={{
          alignItems: 'flex-start',
          overflow: 'auto',
          marginTop: '1rem',
        }}
      >
        <h3>Changed Seeds</h3>
        <Spin spinning={isLoading}>
          {isSuccess ? (
            <Collapse style={{width: '100%'}}>
              {seeds
                ?.filter((seed) => seed.edited)
                .map((seed) => (
                  <Collapse.Panel
                    header={
                      <Link to={`/source_manager/${seed.title}`}>
                        {seed.title}
                      </Link>
                    }
                    key={seed.title}
                    extra={genExtra(seed.title)}
                    className={`${
                      selectedSeeds.includes(seed.title)
                        ? 'seed-changes-panel-selected'
                        : ''
                    }`}
                  >
                    {/* <SeedDiff seedId={seed.title} /> */}
                  </Collapse.Panel>
                ))}
            </Collapse>
          ) : null}
        </Spin>
        <div>
          <p>
            Select the sources above to use in the test run and view projected
            changes to the database.
          </p>
          <Popconfirm
            title="Are you sure you want to run the DBT? This action may take a while."
            onConfirm={() => setDiffs(['diff'])}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Start Test Run</Button>
          </Popconfirm>
        </div>
        <h3>Database Changes</h3>
        {!diffs.length ? (
          <p>View the changes to the database after running the DBT.</p>
        ) : (
          <>
            <Collapse style={{width: '100%'}}>
              {diffs.map((diff) => (
                <Collapse.Panel
                  header={diff}
                  key={diff}
                  extra={genExtraDiffs(diff)}
                  className={`${
                    reviewed.includes(diff) ? 'seed-changes-panel-selected' : ''
                  }`}
                >
                  {/* <DiffCompare diffs={mockDiffs} diffCols={diffCols} /> */}
                </Collapse.Panel>
              ))}
            </Collapse>
            <Popconfirm
              title="Are you sure you want to save these changes permanently?"
              onConfirm={() => console.log('save changes')}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" disabled={!checkIfAllReviewed()}>
                Save Changes Permanently
              </Button>
            </Popconfirm>
          </>
        )}
      </div>
    </div>
  );
};

export default SeedChangesPage;
