import {Card} from 'antd';
import {useFetchPageQuery, useFetchPagesQuery} from 'api/cmsSlice';
import Catalog from 'components/genericComponents/Catalog';
import Chat from 'components/search/ChatBot';
import SearchPortal from 'components/search/SearchPortal';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const spotlightSlugs =
  process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? [7, 73, 188, 31, 63]
    : [96, 98, 8, 4, 6];

const PageCard = ({slug}) => {
  const {data: page} = useFetchPageQuery({slug, version: 'published'});

  if (!page) {
    return null;
  }

  return (
    <Link to={`/data_portal/${page.slug}`}>
      <Card
        bordered={false}
        style={{
          backgroundColor: '#f9f9f9',
          margin: '10px 0',
        }}
      >
        <h6>{`${page.path.join(' > ')} > ${page.title}`}</h6>
      </Card>
    </Link>
  );
};

const SearchPage = () => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];

  const {data: pages, isLoading: pagesLoading} =
    useFetchPagesQuery('published');

  if (path === 'search_chat') {
    return (
      <div>
        <h1>Search Bot</h1>
        <Chat style={{height: '80vh'}} />
      </div>
    );
  }

  return (
    <>
      <h1>Data Portal</h1>
      <div className="flex-row">
        <div style={{width: 'calc(100% - 430px)'}}>
          <Catalog loading={pagesLoading} items={pages} path="data_portal" />
        </div>
        <div style={{width: 390, margin: 15}}>
          <h2>Editor's Picks</h2>
          {spotlightSlugs.map((slug) => (
            <PageCard key={slug} slug={slug} />
          ))}
        </div>
      </div>
      <SearchPortal />
    </>
  );
};

export default SearchPage;
