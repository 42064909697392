import {CloseCircleOutlined} from '@ant-design/icons';
import {Alert, Button} from 'antd';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import SortButton from 'components/genericComponents/SortButton';
import React, {useMemo} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';
import {validMetricsForColumnType, validTypesForFilter} from 'utils/consts';

const Container = styled.div`
  align-items: start;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  &:hover {
    border-color: #aaa;
  }
`;

const ColumnForm = ({column, columns, handleDelete, idx, setColumn}) => {
  const parsedColumns = useMemo(() => {
    return columns
      .map((column) => ({
        value: column.column_name,
        label: `${column.column_name} (${column.data_type})`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [columns]);

  const metricOptions = useMemo(() => {
    return Object.keys(validMetricsForColumnType).map((option) => {
      return {
        value: option,
        label: option,
        disabled: !validMetricsForColumnType[option].includes(
          columns.find((col) => col.column_name === column.source_column)
            ?.data_type
        ),
      };
    });
  }, [columns, column.source_column]);

  const filterOptions = useMemo(() => {
    return Object.keys(validTypesForFilter).map((option) => {
      return {
        value: option,
        label: option,
        disabled: !validTypesForFilter[option].includes(
          columns.find((col) => col.column_name === column.source_column)
            ?.data_type
        ),
      };
    });
  }, [columns, column.source_column]);

  const handleColumnChanged = (value, field) => {
    let updatedColumn = {
      ...column,
      [field]: value,
    };
    if (field === 'source_column') {
      updatedColumn.data_type = columns.find(
        (column) => column.column_name === value
      )?.data_type;
      updatedColumn.alias = value;
      updatedColumn.errors = [];
    }
    if (
      updatedColumn.column_type === 'METRIC' &&
      (field === 'metric_type' || field === 'source_column')
    ) {
      updatedColumn.alias = autogenerateColumnName(updatedColumn);
    }
    setColumn(updatedColumn);
  };

  const autogenerateColumnName = (metric) => {
    const c = metric.source_column?.toLowerCase().replace(' ', '_') ?? '';
    const m = metric.metric_type?.toLowerCase().replace(' ', '_') ?? '';
    return `${c}_${m}`;
  };

  return (
    <Draggable draggableId={`column-${column.id}`} index={idx} key={column.id}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="flex-row"
        >
          <CloseCircleOutlined
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            title="Remove Column"
          />
          <div className="flex-column">
            <div className="flex-row">
              <InputField
                label="Name"
                onChange={(e) => handleColumnChanged(e.target.value, 'alias')}
                required={true}
                style={{width: '150px'}}
                value={column.alias ?? column.source_column}
              />
              <SelectOptions
                label="Source Column"
                onChange={(value) =>
                  handleColumnChanged(value, 'source_column')
                }
                options={parsedColumns}
                required={true}
                style={{width: '190px'}}
                value={column.source_column}
              />
              <SelectOptions
                label="Filter Type"
                onChange={(value) => handleColumnChanged(value, 'filter_type')}
                options={filterOptions}
                required={true}
                style={{width: '130px'}}
                value={column.filter_type}
              />
            </div>
            {column.column_type !== 'FILTER' && (
              <div className="flex-row">
                <SelectOptions
                  label="Column Type"
                  onChange={(value) =>
                    handleColumnChanged(value, 'column_type')
                  }
                  options={['DIMENSION', 'METRIC']}
                  required={true}
                  style={{width: '150px'}}
                  value={column.column_type}
                />
                {column.column_type === 'METRIC' && (
                  <SelectOptions
                    label="Metric"
                    onChange={(value) =>
                      handleColumnChanged(value, 'metric_type')
                    }
                    options={metricOptions}
                    required={true}
                    style={{width: '190px'}}
                    value={column.metric_type}
                  />
                )}
                {column.column_type === 'DIMENSION' && (
                  <SelectOptions
                    label="Link Text Column"
                    onChange={(value) =>
                      handleColumnChanged(value, 'link_text')
                    }
                    options={parsedColumns}
                    style={{width: '190px'}}
                    value={column.link_text}
                  />
                )}
                <span style={{flex: 1}} />
                <SortButton column={column} setColumn={handleColumnChanged} />
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleColumnChanged(!column.freeze, 'freeze');
                  }}
                  size="large"
                  style={{
                    border: column.freeze
                      ? '1px solid var(--primary-color)'
                      : '1px solid #ddd',
                    color: column.freeze
                      ? 'var(--primary-color)'
                      : 'rgba(0, 0, 0, 0.25)',
                    fontWeight: 600,
                    fontSize: '1.2em',
                  }}
                  title="Freeze column"
                >
                  &#10052;
                </Button>
              </div>
            )}
            {column.errors?.length > 0 && (
              <Alert
                message={
                  <div>
                    {column.errors?.map((error) => (
                      <div key={error}>* {error}</div>
                    ))}
                  </div>
                }
                type="error"
              />
            )}
          </div>
        </Container>
      )}
    </Draggable>
  );
};

export default ColumnForm;
