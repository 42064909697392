import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  accessToken: null,
  error: null,
  isLoading: false,
};

export const googleAuthSlice = createSlice({
  name: 'googleAuth',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      state.isAuthenticated = Boolean(action.payload);
    },
  },
});

export const {setError, clearError, setAccessToken} = googleAuthSlice.actions;

export default googleAuthSlice.reducer;
